export const CardAddAffiliate = () => import('../../components/account/CardAddAffiliate.vue' /* webpackChunkName: "components/card-add-affiliate" */).then(c => wrapFunctional(c.default || c))
export const CardProductAffiliate = () => import('../../components/account/CardProductAffiliate.vue' /* webpackChunkName: "components/card-product-affiliate" */).then(c => wrapFunctional(c.default || c))
export const FooterAcc = () => import('../../components/account/FooterAcc.vue' /* webpackChunkName: "components/footer-acc" */).then(c => wrapFunctional(c.default || c))
export const ModalDeleteAccount = () => import('../../components/account/ModalDeleteAccount.vue' /* webpackChunkName: "components/modal-delete-account" */).then(c => wrapFunctional(c.default || c))
export const NotificationSection = () => import('../../components/account/NotificationSection.vue' /* webpackChunkName: "components/notification-section" */).then(c => wrapFunctional(c.default || c))
export const PoinSection = () => import('../../components/account/PoinSection.vue' /* webpackChunkName: "components/poin-section" */).then(c => wrapFunctional(c.default || c))
export const SidebarAcc = () => import('../../components/account/SidebarAcc.vue' /* webpackChunkName: "components/sidebar-acc" */).then(c => wrapFunctional(c.default || c))
export const CardBenefit = () => import('../../components/affiliate/CardBenefit.vue' /* webpackChunkName: "components/card-benefit" */).then(c => wrapFunctional(c.default || c))
export const CardPartnerAffiliate = () => import('../../components/affiliate/CardPartnerAffiliate.vue' /* webpackChunkName: "components/card-partner-affiliate" */).then(c => wrapFunctional(c.default || c))
export const DashboardJoinAffiliate = () => import('../../components/affiliate/DashboardJoinAffiliate.vue' /* webpackChunkName: "components/dashboard-join-affiliate" */).then(c => wrapFunctional(c.default || c))
export const FooterJoinAffiliate = () => import('../../components/affiliate/FooterJoinAffiliate.vue' /* webpackChunkName: "components/footer-join-affiliate" */).then(c => wrapFunctional(c.default || c))
export const GuidelineAffiliate = () => import('../../components/affiliate/GuidelineAffiliate.vue' /* webpackChunkName: "components/guideline-affiliate" */).then(c => wrapFunctional(c.default || c))
export const QuestionAffiliate = () => import('../../components/affiliate/QuestionAffiliate.vue' /* webpackChunkName: "components/question-affiliate" */).then(c => wrapFunctional(c.default || c))
export const AuthLayoutWrapper = () => import('../../components/auth/AuthLayoutWrapper.vue' /* webpackChunkName: "components/auth-layout-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ForgotPasswordForm = () => import('../../components/auth/ForgotPasswordForm.vue' /* webpackChunkName: "components/forgot-password-form" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/auth/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingSection = () => import('../../components/auth/OnBoardingSection.vue' /* webpackChunkName: "components/on-boarding-section" */).then(c => wrapFunctional(c.default || c))
export const PopupAuth = () => import('../../components/auth/PopupAuth.vue' /* webpackChunkName: "components/popup-auth" */).then(c => wrapFunctional(c.default || c))
export const RegisterForm = () => import('../../components/auth/RegisterForm.vue' /* webpackChunkName: "components/register-form" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordForm = () => import('../../components/auth/ResetPasswordForm.vue' /* webpackChunkName: "components/reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const BannerShareAffiliate = () => import('../../components/banners/BannerShareAffiliate.vue' /* webpackChunkName: "components/banner-share-affiliate" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/base/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const AlertAddToCart = () => import('../../components/base/AlertAddToCart.vue' /* webpackChunkName: "components/alert-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AlertNotLogin = () => import('../../components/base/AlertNotLogin.vue' /* webpackChunkName: "components/alert-not-login" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/base/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const BannerProfileSection = () => import('../../components/base/BannerProfileSection.vue' /* webpackChunkName: "components/banner-profile-section" */).then(c => wrapFunctional(c.default || c))
export const BottomNavigation = () => import('../../components/base/BottomNavigation.vue' /* webpackChunkName: "components/bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const BrandCardSkeleton = () => import('../../components/base/BrandCardSkeleton.vue' /* webpackChunkName: "components/brand-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/base/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonShareSocialMedia = () => import('../../components/base/ButtonShareSocialMedia.vue' /* webpackChunkName: "components/button-share-social-media" */).then(c => wrapFunctional(c.default || c))
export const CardBase = () => import('../../components/base/CardBase.vue' /* webpackChunkName: "components/card-base" */).then(c => wrapFunctional(c.default || c))
export const CatalogForPartner = () => import('../../components/base/CatalogForPartner.vue' /* webpackChunkName: "components/catalog-for-partner" */).then(c => wrapFunctional(c.default || c))
export const CategoryProfileSection = () => import('../../components/base/CategoryProfileSection.vue' /* webpackChunkName: "components/category-profile-section" */).then(c => wrapFunctional(c.default || c))
export const Clamp = () => import('../../components/base/Clamp.vue' /* webpackChunkName: "components/clamp" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/base/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const DividerVertical = () => import('../../components/base/DividerVertical.vue' /* webpackChunkName: "components/divider-vertical" */).then(c => wrapFunctional(c.default || c))
export const EmptyNotification = () => import('../../components/base/EmptyNotification.vue' /* webpackChunkName: "components/empty-notification" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../components/base/EmptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const HeaderProfileSection = () => import('../../components/base/HeaderProfileSection.vue' /* webpackChunkName: "components/header-profile-section" */).then(c => wrapFunctional(c.default || c))
export const LittleBanner = () => import('../../components/base/LittleBanner.vue' /* webpackChunkName: "components/little-banner" */).then(c => wrapFunctional(c.default || c))
export const LorikeetProfileSection = () => import('../../components/base/LorikeetProfileSection.vue' /* webpackChunkName: "components/lorikeet-profile-section" */).then(c => wrapFunctional(c.default || c))
export const ModalBase = () => import('../../components/base/ModalBase.vue' /* webpackChunkName: "components/modal-base" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateBankAccount = () => import('../../components/base/ModalCreateBankAccount.vue' /* webpackChunkName: "components/modal-create-bank-account" */).then(c => wrapFunctional(c.default || c))
export const ModalCropImage = () => import('../../components/base/ModalCropImage.vue' /* webpackChunkName: "components/modal-crop-image" */).then(c => wrapFunctional(c.default || c))
export const ModalShareLink = () => import('../../components/base/ModalShareLink.vue' /* webpackChunkName: "components/modal-share-link" */).then(c => wrapFunctional(c.default || c))
export const PopupAddToCart = () => import('../../components/base/PopupAddToCart.vue' /* webpackChunkName: "components/popup-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const PopupListContact = () => import('../../components/base/PopupListContact.vue' /* webpackChunkName: "components/popup-list-contact" */).then(c => wrapFunctional(c.default || c))
export const PopupListVoucherProfile = () => import('../../components/base/PopupListVoucherProfile.vue' /* webpackChunkName: "components/popup-list-voucher-profile" */).then(c => wrapFunctional(c.default || c))
export const PopupLocation = () => import('../../components/base/PopupLocation.vue' /* webpackChunkName: "components/popup-location" */).then(c => wrapFunctional(c.default || c))
export const ProductCardInfiniteScroll = () => import('../../components/base/ProductCardInfiniteScroll.vue' /* webpackChunkName: "components/product-card-infinite-scroll" */).then(c => wrapFunctional(c.default || c))
export const ProductCardSkeletonLoading = () => import('../../components/base/ProductCardSkeletonLoading.vue' /* webpackChunkName: "components/product-card-skeleton-loading" */).then(c => wrapFunctional(c.default || c))
export const ShareSection = () => import('../../components/base/ShareSection.vue' /* webpackChunkName: "components/share-section" */).then(c => wrapFunctional(c.default || c))
export const SkeletonCard = () => import('../../components/base/SkeletonCard.vue' /* webpackChunkName: "components/skeleton-card" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/base/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailListProduct = () => import('../../components/base/ThumbnailListProduct.vue' /* webpackChunkName: "components/thumbnail-list-product" */).then(c => wrapFunctional(c.default || c))
export const VideoProfileSection = () => import('../../components/base/VideoProfileSection.vue' /* webpackChunkName: "components/video-profile-section" */).then(c => wrapFunctional(c.default || c))
export const VoucherProfileSection = () => import('../../components/base/VoucherProfileSection.vue' /* webpackChunkName: "components/voucher-profile-section" */).then(c => wrapFunctional(c.default || c))
export const AllCategoryDrawer = () => import('../../components/home/AllCategoryDrawer.vue' /* webpackChunkName: "components/all-category-drawer" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDescriptionSection = () => import('../../components/home/ApplicationDescriptionSection.vue' /* webpackChunkName: "components/application-description-section" */).then(c => wrapFunctional(c.default || c))
export const BannerBottomSection = () => import('../../components/home/BannerBottomSection.vue' /* webpackChunkName: "components/banner-bottom-section" */).then(c => wrapFunctional(c.default || c))
export const BannerPromotionExclusive = () => import('../../components/home/BannerPromotionExclusive.vue' /* webpackChunkName: "components/banner-promotion-exclusive" */).then(c => wrapFunctional(c.default || c))
export const BestSellerProducts = () => import('../../components/home/BestSellerProducts.vue' /* webpackChunkName: "components/best-seller-products" */).then(c => wrapFunctional(c.default || c))
export const CategorySection = () => import('../../components/home/CategorySection.vue' /* webpackChunkName: "components/category-section" */).then(c => wrapFunctional(c.default || c))
export const Featured = () => import('../../components/home/Featured.vue' /* webpackChunkName: "components/featured" */).then(c => wrapFunctional(c.default || c))
export const FlashsaleSection = () => import('../../components/home/FlashsaleSection.vue' /* webpackChunkName: "components/flashsale-section" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/home/HomeBanner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeBannerPromotion = () => import('../../components/home/HomeBannerPromotion.vue' /* webpackChunkName: "components/home-banner-promotion" */).then(c => wrapFunctional(c.default || c))
export const HomeBannerPromotionBrand = () => import('../../components/home/HomeBannerPromotionBrand.vue' /* webpackChunkName: "components/home-banner-promotion-brand" */).then(c => wrapFunctional(c.default || c))
export const HomeBrandPartner = () => import('../../components/home/HomeBrandPartner.vue' /* webpackChunkName: "components/home-brand-partner" */).then(c => wrapFunctional(c.default || c))
export const NearestStore = () => import('../../components/home/NearestStore.vue' /* webpackChunkName: "components/nearest-store" */).then(c => wrapFunctional(c.default || c))
export const NewestProducts = () => import('../../components/home/NewestProducts.vue' /* webpackChunkName: "components/newest-products" */).then(c => wrapFunctional(c.default || c))
export const PanintiExclusiveSection = () => import('../../components/home/PanintiExclusiveSection.vue' /* webpackChunkName: "components/paninti-exclusive-section" */).then(c => wrapFunctional(c.default || c))
export const ParentinghubSection = () => import('../../components/home/ParentinghubSection.vue' /* webpackChunkName: "components/parentinghub-section" */).then(c => wrapFunctional(c.default || c))
export const PopularSearch = () => import('../../components/home/PopularSearch.vue' /* webpackChunkName: "components/popular-search" */).then(c => wrapFunctional(c.default || c))
export const PopupHomepage = () => import('../../components/home/PopupHomepage.vue' /* webpackChunkName: "components/popup-homepage" */).then(c => wrapFunctional(c.default || c))
export const ProductsBrand = () => import('../../components/home/ProductsBrand.vue' /* webpackChunkName: "components/products-brand" */).then(c => wrapFunctional(c.default || c))
export const ProductsStore = () => import('../../components/home/ProductsStore.vue' /* webpackChunkName: "components/products-store" */).then(c => wrapFunctional(c.default || c))
export const ProductsTrending = () => import('../../components/home/ProductsTrending.vue' /* webpackChunkName: "components/products-trending" */).then(c => wrapFunctional(c.default || c))
export const PromotionSection = () => import('../../components/home/PromotionSection.vue' /* webpackChunkName: "components/promotion-section" */).then(c => wrapFunctional(c.default || c))
export const RecommendationSection = () => import('../../components/home/RecommendationSection.vue' /* webpackChunkName: "components/recommendation-section" */).then(c => wrapFunctional(c.default || c))
export const IconAmericanExpress = () => import('../../components/icons/IconAmericanExpress.vue' /* webpackChunkName: "components/icon-american-express" */).then(c => wrapFunctional(c.default || c))
export const IconArrowLeft = () => import('../../components/icons/IconArrowLeft.vue' /* webpackChunkName: "components/icon-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconBagDiscount = () => import('../../components/icons/IconBagDiscount.vue' /* webpackChunkName: "components/icon-bag-discount" */).then(c => wrapFunctional(c.default || c))
export const IconBoxProcessed = () => import('../../components/icons/IconBoxProcessed.vue' /* webpackChunkName: "components/icon-box-processed" */).then(c => wrapFunctional(c.default || c))
export const IconBxsStore = () => import('../../components/icons/IconBxsStore.vue' /* webpackChunkName: "components/icon-bxs-store" */).then(c => wrapFunctional(c.default || c))
export const IconCalendar = () => import('../../components/icons/IconCalendar.vue' /* webpackChunkName: "components/icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight = () => import('../../components/icons/IconChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRightLarge = () => import('../../components/icons/IconChevronRightLarge.vue' /* webpackChunkName: "components/icon-chevron-right-large" */).then(c => wrapFunctional(c.default || c))
export const IconClock = () => import('../../components/icons/IconClock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c))
export const IconCopy = () => import('../../components/icons/IconCopy.vue' /* webpackChunkName: "components/icon-copy" */).then(c => wrapFunctional(c.default || c))
export const IconEditorCopy = () => import('../../components/icons/IconEditorCopy.vue' /* webpackChunkName: "components/icon-editor-copy" */).then(c => wrapFunctional(c.default || c))
export const IconEmail = () => import('../../components/icons/IconEmail.vue' /* webpackChunkName: "components/icon-email" */).then(c => wrapFunctional(c.default || c))
export const IconFilter = () => import('../../components/icons/IconFilter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c))
export const IconHeartFill = () => import('../../components/icons/IconHeartFill.vue' /* webpackChunkName: "components/icon-heart-fill" */).then(c => wrapFunctional(c.default || c))
export const IconHeartLikeWishlistEmpty = () => import('../../components/icons/IconHeartLikeWishlistEmpty.vue' /* webpackChunkName: "components/icon-heart-like-wishlist-empty" */).then(c => wrapFunctional(c.default || c))
export const IconHeartLikeWishlistFilled = () => import('../../components/icons/IconHeartLikeWishlistFilled.vue' /* webpackChunkName: "components/icon-heart-like-wishlist-filled" */).then(c => wrapFunctional(c.default || c))
export const IconHeartOutline = () => import('../../components/icons/IconHeartOutline.vue' /* webpackChunkName: "components/icon-heart-outline" */).then(c => wrapFunctional(c.default || c))
export const IconHome = () => import('../../components/icons/IconHome.vue' /* webpackChunkName: "components/icon-home" */).then(c => wrapFunctional(c.default || c))
export const IconInfo = () => import('../../components/icons/IconInfo.vue' /* webpackChunkName: "components/icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconInstagram = () => import('../../components/icons/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconJCB = () => import('../../components/icons/IconJCB.vue' /* webpackChunkName: "components/icon-j-c-b" */).then(c => wrapFunctional(c.default || c))
export const IconKindergarten = () => import('../../components/icons/IconKindergarten.vue' /* webpackChunkName: "components/icon-kindergarten" */).then(c => wrapFunctional(c.default || c))
export const IconLeft = () => import('../../components/icons/IconLeft.vue' /* webpackChunkName: "components/icon-left" */).then(c => wrapFunctional(c.default || c))
export const IconLoading = () => import('../../components/icons/IconLoading.vue' /* webpackChunkName: "components/icon-loading" */).then(c => wrapFunctional(c.default || c))
export const IconLogout = () => import('../../components/icons/IconLogout.vue' /* webpackChunkName: "components/icon-logout" */).then(c => wrapFunctional(c.default || c))
export const IconMasterCard = () => import('../../components/icons/IconMasterCard.vue' /* webpackChunkName: "components/icon-master-card" */).then(c => wrapFunctional(c.default || c))
export const IconPanintiCoin = () => import('../../components/icons/IconPanintiCoin.vue' /* webpackChunkName: "components/icon-paninti-coin" */).then(c => wrapFunctional(c.default || c))
export const IconPinLocation = () => import('../../components/icons/IconPinLocation.vue' /* webpackChunkName: "components/icon-pin-location" */).then(c => wrapFunctional(c.default || c))
export const IconPinPoint = () => import('../../components/icons/IconPinPoint.vue' /* webpackChunkName: "components/icon-pin-point" */).then(c => wrapFunctional(c.default || c))
export const IconPlus = () => import('../../components/icons/IconPlus.vue' /* webpackChunkName: "components/icon-plus" */).then(c => wrapFunctional(c.default || c))
export const IconPuzzle = () => import('../../components/icons/IconPuzzle.vue' /* webpackChunkName: "components/icon-puzzle" */).then(c => wrapFunctional(c.default || c))
export const IconSearch = () => import('../../components/icons/IconSearch.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c))
export const IconShareAndroid = () => import('../../components/icons/IconShareAndroid.vue' /* webpackChunkName: "components/icon-share-android" */).then(c => wrapFunctional(c.default || c))
export const IconSocialFacebook = () => import('../../components/icons/IconSocialFacebook.vue' /* webpackChunkName: "components/icon-social-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconSocialInstagram = () => import('../../components/icons/IconSocialInstagram.vue' /* webpackChunkName: "components/icon-social-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconSocialTiktok = () => import('../../components/icons/IconSocialTiktok.vue' /* webpackChunkName: "components/icon-social-tiktok" */).then(c => wrapFunctional(c.default || c))
export const IconSocialTwitterX = () => import('../../components/icons/IconSocialTwitterX.vue' /* webpackChunkName: "components/icon-social-twitter-x" */).then(c => wrapFunctional(c.default || c))
export const IconSocialWhatsapp = () => import('../../components/icons/IconSocialWhatsapp.vue' /* webpackChunkName: "components/icon-social-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const IconSocialYoutube = () => import('../../components/icons/IconSocialYoutube.vue' /* webpackChunkName: "components/icon-social-youtube" */).then(c => wrapFunctional(c.default || c))
export const IconStar = () => import('../../components/icons/IconStar.vue' /* webpackChunkName: "components/icon-star" */).then(c => wrapFunctional(c.default || c))
export const IconStarFilled = () => import('../../components/icons/IconStarFilled.vue' /* webpackChunkName: "components/icon-star-filled" */).then(c => wrapFunctional(c.default || c))
export const IconStore = () => import('../../components/icons/IconStore.vue' /* webpackChunkName: "components/icon-store" */).then(c => wrapFunctional(c.default || c))
export const IconTiktok = () => import('../../components/icons/IconTiktok.vue' /* webpackChunkName: "components/icon-tiktok" */).then(c => wrapFunctional(c.default || c))
export const IconTime = () => import('../../components/icons/IconTime.vue' /* webpackChunkName: "components/icon-time" */).then(c => wrapFunctional(c.default || c))
export const IconTimes = () => import('../../components/icons/IconTimes.vue' /* webpackChunkName: "components/icon-times" */).then(c => wrapFunctional(c.default || c))
export const IconTrash = () => import('../../components/icons/IconTrash.vue' /* webpackChunkName: "components/icon-trash" */).then(c => wrapFunctional(c.default || c))
export const IconVisa = () => import('../../components/icons/IconVisa.vue' /* webpackChunkName: "components/icon-visa" */).then(c => wrapFunctional(c.default || c))
export const IconWarning = () => import('../../components/icons/IconWarning.vue' /* webpackChunkName: "components/icon-warning" */).then(c => wrapFunctional(c.default || c))
export const IconYoutube = () => import('../../components/icons/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const FormAddress = () => import('../../components/account/address/FormAddress.vue' /* webpackChunkName: "components/form-address" */).then(c => wrapFunctional(c.default || c))
export const FormCreateAddress = () => import('../../components/account/address/FormCreateAddress.vue' /* webpackChunkName: "components/form-create-address" */).then(c => wrapFunctional(c.default || c))
export const FormEditAddress = () => import('../../components/account/address/FormEditAddress.vue' /* webpackChunkName: "components/form-edit-address" */).then(c => wrapFunctional(c.default || c))
export const ListAccountAddresses = () => import('../../components/account/address/ListAccountAddresses.vue' /* webpackChunkName: "components/list-account-addresses" */).then(c => wrapFunctional(c.default || c))
export const ModalDeleteAddress = () => import('../../components/account/address/ModalDeleteAddress.vue' /* webpackChunkName: "components/modal-delete-address" */).then(c => wrapFunctional(c.default || c))
export const AddProductAffiliate = () => import('../../components/account/affiliate/AddProductAffiliate.vue' /* webpackChunkName: "components/add-product-affiliate" */).then(c => wrapFunctional(c.default || c))
export const DashboardAffiliate = () => import('../../components/account/affiliate/DashboardAffiliate.vue' /* webpackChunkName: "components/dashboard-affiliate" */).then(c => wrapFunctional(c.default || c))
export const ModalCtaJoinAffiliate = () => import('../../components/account/affiliate/ModalCtaJoinAffiliate.vue' /* webpackChunkName: "components/modal-cta-join-affiliate" */).then(c => wrapFunctional(c.default || c))
export const ModalLinkAffiliate = () => import('../../components/account/affiliate/ModalLinkAffiliate.vue' /* webpackChunkName: "components/modal-link-affiliate" */).then(c => wrapFunctional(c.default || c))
export const ModalTermsCondition = () => import('../../components/account/affiliate/ModalTermsCondition.vue' /* webpackChunkName: "components/modal-terms-condition" */).then(c => wrapFunctional(c.default || c))
export const WelcomePageAffiliate = () => import('../../components/account/affiliate/WelcomePageAffiliate.vue' /* webpackChunkName: "components/welcome-page-affiliate" */).then(c => wrapFunctional(c.default || c))
export const CardStatsBank = () => import('../../components/account/bank-account/CardStatsBank.vue' /* webpackChunkName: "components/card-stats-bank" */).then(c => wrapFunctional(c.default || c))
export const ListBankAccount = () => import('../../components/account/bank-account/ListBankAccount.vue' /* webpackChunkName: "components/list-bank-account" */).then(c => wrapFunctional(c.default || c))
export const ModalDeleteAccountBank = () => import('../../components/account/bank-account/ModalDeleteAccountBank.vue' /* webpackChunkName: "components/modal-delete-account-bank" */).then(c => wrapFunctional(c.default || c))
export const AccountLastPurchasedProducts = () => import('../../components/account/home/AccountLastPurchasedProducts.vue' /* webpackChunkName: "components/account-last-purchased-products" */).then(c => wrapFunctional(c.default || c))
export const AccountPromotion = () => import('../../components/account/home/AccountPromotion.vue' /* webpackChunkName: "components/account-promotion" */).then(c => wrapFunctional(c.default || c))
export const AccountRecommendationProducts = () => import('../../components/account/home/AccountRecommendationProducts.vue' /* webpackChunkName: "components/account-recommendation-products" */).then(c => wrapFunctional(c.default || c))
export const LastPurchasedCard = () => import('../../components/account/home/LastPurchasedCard.vue' /* webpackChunkName: "components/last-purchased-card" */).then(c => wrapFunctional(c.default || c))
export const LatestReviewCard = () => import('../../components/account/home/LatestReviewCard.vue' /* webpackChunkName: "components/latest-review-card" */).then(c => wrapFunctional(c.default || c))
export const MyPoint = () => import('../../components/account/home/MyPoint.vue' /* webpackChunkName: "components/my-point" */).then(c => wrapFunctional(c.default || c))
export const ProductRecommendationCard = () => import('../../components/account/home/ProductRecommendationCard.vue' /* webpackChunkName: "components/product-recommendation-card" */).then(c => wrapFunctional(c.default || c))
export const MyFavoriteEmptyProduct = () => import('../../components/account/my-favorite/MyFavoriteEmptyProduct.vue' /* webpackChunkName: "components/my-favorite-empty-product" */).then(c => wrapFunctional(c.default || c))
export const MyFavoriteProductSection = () => import('../../components/account/my-favorite/MyFavoriteProductSection.vue' /* webpackChunkName: "components/my-favorite-product-section" */).then(c => wrapFunctional(c.default || c))
export const Modal3DS = () => import('../../components/account/payment/Modal3DS.vue' /* webpackChunkName: "components/modal3-d-s" */).then(c => wrapFunctional(c.default || c))
export const PopupCreditCard = () => import('../../components/account/payment/PopupCreditCard.vue' /* webpackChunkName: "components/popup-credit-card" */).then(c => wrapFunctional(c.default || c))
export const ModalPointTerms = () => import('../../components/account/point/ModalPointTerms.vue' /* webpackChunkName: "components/modal-point-terms" */).then(c => wrapFunctional(c.default || c))
export const PointHistoryItem = () => import('../../components/account/point/PointHistoryItem.vue' /* webpackChunkName: "components/point-history-item" */).then(c => wrapFunctional(c.default || c))
export const UserPointSection = () => import('../../components/account/point/UserPointSection.vue' /* webpackChunkName: "components/user-point-section" */).then(c => wrapFunctional(c.default || c))
export const FormEditPassword = () => import('../../components/account/profile/FormEditPassword.vue' /* webpackChunkName: "components/form-edit-password" */).then(c => wrapFunctional(c.default || c))
export const FormEditProfile = () => import('../../components/account/profile/FormEditProfile.vue' /* webpackChunkName: "components/form-edit-profile" */).then(c => wrapFunctional(c.default || c))
export const ProfileBanner = () => import('../../components/account/profile/ProfileBanner.vue' /* webpackChunkName: "components/profile-banner" */).then(c => wrapFunctional(c.default || c))
export const ProfileData = () => import('../../components/account/profile/ProfileData.vue' /* webpackChunkName: "components/profile-data" */).then(c => wrapFunctional(c.default || c))
export const ModalDetailVoucherBackup = () => import('../../components/account/voucher/ModalDetailVoucherBackup.vue' /* webpackChunkName: "components/modal-detail-voucher-backup" */).then(c => wrapFunctional(c.default || c))
export const UserVoucherCard = () => import('../../components/account/voucher/UserVoucherCard.vue' /* webpackChunkName: "components/user-voucher-card" */).then(c => wrapFunctional(c.default || c))
export const UserVoucherCardSkeleton = () => import('../../components/account/voucher/UserVoucherCardSkeleton.vue' /* webpackChunkName: "components/user-voucher-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const UserVoucherSection = () => import('../../components/account/voucher/UserVoucherSection.vue' /* webpackChunkName: "components/user-voucher-section" */).then(c => wrapFunctional(c.default || c))
export const FormLoginAffiliate = () => import('../../components/affiliate/create-affiliate/FormLoginAffiliate.vue' /* webpackChunkName: "components/form-login-affiliate" */).then(c => wrapFunctional(c.default || c))
export const FormSocialMediaAffiliate = () => import('../../components/affiliate/create-affiliate/FormSocialMediaAffiliate.vue' /* webpackChunkName: "components/form-social-media-affiliate" */).then(c => wrapFunctional(c.default || c))
export const FormTncAffiliate = () => import('../../components/affiliate/create-affiliate/FormTncAffiliate.vue' /* webpackChunkName: "components/form-tnc-affiliate" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateAccount = () => import('../../components/affiliate/create-affiliate/ModalCreateAccount.vue' /* webpackChunkName: "components/modal-create-account" */).then(c => wrapFunctional(c.default || c))
export const ModalOtpCreateAccount = () => import('../../components/affiliate/create-affiliate/ModalOtpCreateAccount.vue' /* webpackChunkName: "components/modal-otp-create-account" */).then(c => wrapFunctional(c.default || c))
export const PopularMonthly = () => import('../../components/affiliate/home/PopularMonthly.vue' /* webpackChunkName: "components/popular-monthly" */).then(c => wrapFunctional(c.default || c))
export const ProductAffiliatePopular = () => import('../../components/affiliate/home/ProductAffiliatePopular.vue' /* webpackChunkName: "components/product-affiliate-popular" */).then(c => wrapFunctional(c.default || c))
export const TopBrandAffiliate = () => import('../../components/affiliate/home/TopBrandAffiliate.vue' /* webpackChunkName: "components/top-brand-affiliate" */).then(c => wrapFunctional(c.default || c))
export const CatalogAffiliateProduct = () => import('../../components/affiliate/products/CatalogAffiliateProduct.vue' /* webpackChunkName: "components/catalog-affiliate-product" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/base/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const ElInputLabelInside = () => import('../../components/base/form/ElInputLabelInside.vue' /* webpackChunkName: "components/el-input-label-inside" */).then(c => wrapFunctional(c.default || c))
export const ElSelectLabelInside = () => import('../../components/base/form/ElSelectLabelInside.vue' /* webpackChunkName: "components/el-select-label-inside" */).then(c => wrapFunctional(c.default || c))
export const CardPartnerRelated = () => import('../../components/base/partner/CardPartnerRelated.vue' /* webpackChunkName: "components/card-partner-related" */).then(c => wrapFunctional(c.default || c))
export const ModalPartnerRelatedData = () => import('../../components/base/partner/ModalPartnerRelatedData.vue' /* webpackChunkName: "components/modal-partner-related-data" */).then(c => wrapFunctional(c.default || c))
export const ModalSharePartner = () => import('../../components/base/partner/ModalSharePartner.vue' /* webpackChunkName: "components/modal-share-partner" */).then(c => wrapFunctional(c.default || c))
export const PartnerRelatedDataItem = () => import('../../components/base/partner/PartnerRelatedDataItem.vue' /* webpackChunkName: "components/partner-related-data-item" */).then(c => wrapFunctional(c.default || c))
export const PartnerTypeBadge = () => import('../../components/base/partner/PartnerTypeBadge.vue' /* webpackChunkName: "components/partner-type-badge" */).then(c => wrapFunctional(c.default || c))
export const SkeletonRelated = () => import('../../components/base/partner/SkeletonRelated.vue' /* webpackChunkName: "components/skeleton-related" */).then(c => wrapFunctional(c.default || c))
export const ListProductsOverview = () => import('../../components/base/product/ListProductsOverview.vue' /* webpackChunkName: "components/list-products-overview" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/base/product/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductsFilterMobile = () => import('../../components/base/product/ProductsFilterMobile.vue' /* webpackChunkName: "components/products-filter-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductsFilterMobileDrawer = () => import('../../components/base/product/ProductsFilterMobileDrawer.vue' /* webpackChunkName: "components/products-filter-mobile-drawer" */).then(c => wrapFunctional(c.default || c))
export const ProductsSideFilter = () => import('../../components/base/product/ProductsSideFilter.vue' /* webpackChunkName: "components/products-side-filter" */).then(c => wrapFunctional(c.default || c))
export const RatingPartner = () => import('../../components/base/rating/RatingPartner.vue' /* webpackChunkName: "components/rating-partner" */).then(c => wrapFunctional(c.default || c))
export const CardVoucherNew = () => import('../../components/base/voucher/CardVoucherNew.vue' /* webpackChunkName: "components/card-voucher-new" */).then(c => wrapFunctional(c.default || c))
export const ModalDetailVoucher = () => import('../../components/base/voucher/ModalDetailVoucher.vue' /* webpackChunkName: "components/modal-detail-voucher" */).then(c => wrapFunctional(c.default || c))
export const VoucherCardSkeletonLoading = () => import('../../components/base/voucher/VoucherCardSkeletonLoading.vue' /* webpackChunkName: "components/voucher-card-skeleton-loading" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/home/base/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CardAddress = () => import('../../components/home/base/CardAddress.vue' /* webpackChunkName: "components/card-address" */).then(c => wrapFunctional(c.default || c))
export const CardParentinghubArticle = () => import('../../components/home/base/CardParentinghubArticle.vue' /* webpackChunkName: "components/card-parentinghub-article" */).then(c => wrapFunctional(c.default || c))
export const CardPartnerSmall = () => import('../../components/home/base/CardPartnerSmall.vue' /* webpackChunkName: "components/card-partner-small" */).then(c => wrapFunctional(c.default || c))
export const CardProduct = () => import('../../components/home/base/CardProduct.vue' /* webpackChunkName: "components/card-product" */).then(c => wrapFunctional(c.default || c))
export const CardProductBackup = () => import('../../components/home/base/CardProductBackup.vue' /* webpackChunkName: "components/card-product-backup" */).then(c => wrapFunctional(c.default || c))
export const CardProductFlashsale = () => import('../../components/home/base/CardProductFlashsale.vue' /* webpackChunkName: "components/card-product-flashsale" */).then(c => wrapFunctional(c.default || c))
export const CardProductNotReviewed = () => import('../../components/home/base/CardProductNotReviewed.vue' /* webpackChunkName: "components/card-product-not-reviewed" */).then(c => wrapFunctional(c.default || c))
export const CardVoucher = () => import('../../components/home/base/CardVoucher.vue' /* webpackChunkName: "components/card-voucher" */).then(c => wrapFunctional(c.default || c))
export const CopyrightFooter = () => import('../../components/home/base/CopyrightFooter.vue' /* webpackChunkName: "components/copyright-footer" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/home/base/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/home/base/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const MainSidebar = () => import('../../components/home/base/MainSidebar.vue' /* webpackChunkName: "components/main-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MegamenuCategory = () => import('../../components/home/base/MegamenuCategory.vue' /* webpackChunkName: "components/megamenu-category" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/home/base/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NavbarCart = () => import('../../components/home/base/NavbarCart.vue' /* webpackChunkName: "components/navbar-cart" */).then(c => wrapFunctional(c.default || c))
export const NavbarNotif = () => import('../../components/home/base/NavbarNotif.vue' /* webpackChunkName: "components/navbar-notif" */).then(c => wrapFunctional(c.default || c))
export const SearchSuggestion = () => import('../../components/home/base/SearchSuggestion.vue' /* webpackChunkName: "components/search-suggestion" */).then(c => wrapFunctional(c.default || c))
export const CartListEmptyProduct = () => import('../../components/home/cart/CartListEmptyProduct.vue' /* webpackChunkName: "components/cart-list-empty-product" */).then(c => wrapFunctional(c.default || c))
export const CartListProduct = () => import('../../components/home/cart/CartListProduct.vue' /* webpackChunkName: "components/cart-list-product" */).then(c => wrapFunctional(c.default || c))
export const CartPaycheck = () => import('../../components/home/cart/CartPaycheck.vue' /* webpackChunkName: "components/cart-paycheck" */).then(c => wrapFunctional(c.default || c))
export const CartProductRecommendation = () => import('../../components/home/cart/CartProductRecommendation.vue' /* webpackChunkName: "components/cart-product-recommendation" */).then(c => wrapFunctional(c.default || c))
export const EmptyCart = () => import('../../components/home/cart/EmptyCart.vue' /* webpackChunkName: "components/empty-cart" */).then(c => wrapFunctional(c.default || c))
export const ModalDeleteCart = () => import('../../components/home/cart/ModalDeleteCart.vue' /* webpackChunkName: "components/modal-delete-cart" */).then(c => wrapFunctional(c.default || c))
export const PopupDetailVoucher = () => import('../../components/home/cart/PopupDetailVoucher.vue' /* webpackChunkName: "components/popup-detail-voucher" */).then(c => wrapFunctional(c.default || c))
export const PopupVoucher = () => import('../../components/home/cart/PopupVoucher.vue' /* webpackChunkName: "components/popup-voucher" */).then(c => wrapFunctional(c.default || c))
export const PopupVoucherBackup = () => import('../../components/home/cart/PopupVoucher_backup.vue' /* webpackChunkName: "components/popup-voucher-backup" */).then(c => wrapFunctional(c.default || c))
export const BannerCategory = () => import('../../components/home/category/BannerCategory.vue' /* webpackChunkName: "components/banner-category" */).then(c => wrapFunctional(c.default || c))
export const CatalogListProductCategory = () => import('../../components/home/category/CatalogListProductCategory.vue' /* webpackChunkName: "components/catalog-list-product-category" */).then(c => wrapFunctional(c.default || c))
export const ChildCategory = () => import('../../components/home/category/ChildCategory.vue' /* webpackChunkName: "components/child-category" */).then(c => wrapFunctional(c.default || c))
export const FilterCategorySection = () => import('../../components/home/category/FilterCategorySection.vue' /* webpackChunkName: "components/filter-category-section" */).then(c => wrapFunctional(c.default || c))
export const PopupCategoryFilter = () => import('../../components/home/category/PopupCategoryFilter.vue' /* webpackChunkName: "components/popup-category-filter" */).then(c => wrapFunctional(c.default || c))
export const CardProductStoreBranch = () => import('../../components/home/checkout/CardProductStoreBranch.vue' /* webpackChunkName: "components/card-product-store-branch" */).then(c => wrapFunctional(c.default || c))
export const CardSelectCourier = () => import('../../components/home/checkout/CardSelectCourier.vue' /* webpackChunkName: "components/card-select-courier" */).then(c => wrapFunctional(c.default || c))
export const CardSelectStoreBranch = () => import('../../components/home/checkout/CardSelectStoreBranch.vue' /* webpackChunkName: "components/card-select-store-branch" */).then(c => wrapFunctional(c.default || c))
export const CardShippingAddress = () => import('../../components/home/checkout/CardShippingAddress.vue' /* webpackChunkName: "components/card-shipping-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddress = () => import('../../components/home/checkout/CheckoutAddress.vue' /* webpackChunkName: "components/checkout-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartGroup = () => import('../../components/home/checkout/CheckoutCartGroup.vue' /* webpackChunkName: "components/checkout-cart-group" */).then(c => wrapFunctional(c.default || c))
export const CheckoutMethod = () => import('../../components/home/checkout/CheckoutMethod.vue' /* webpackChunkName: "components/checkout-method" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentMethod = () => import('../../components/home/checkout/CheckoutPaymentMethod.vue' /* webpackChunkName: "components/checkout-payment-method" */).then(c => wrapFunctional(c.default || c))
export const CheckoutUnavailableProduct = () => import('../../components/home/checkout/CheckoutUnavailableProduct.vue' /* webpackChunkName: "components/checkout-unavailable-product" */).then(c => wrapFunctional(c.default || c))
export const PopupBankList = () => import('../../components/home/checkout/PopupBankList.vue' /* webpackChunkName: "components/popup-bank-list" */).then(c => wrapFunctional(c.default || c))
export const PopupCVV = () => import('../../components/home/checkout/PopupCVV.vue' /* webpackChunkName: "components/popup-c-v-v" */).then(c => wrapFunctional(c.default || c))
export const PopupCreateCreditCard = () => import('../../components/home/checkout/PopupCreateCreditCard.vue' /* webpackChunkName: "components/popup-create-credit-card" */).then(c => wrapFunctional(c.default || c))
export const PopupListAddressBackup = () => import('../../components/home/checkout/PopupListAddress_backup.vue' /* webpackChunkName: "components/popup-list-address-backup" */).then(c => wrapFunctional(c.default || c))
export const PopupListCreditCard = () => import('../../components/home/checkout/PopupListCreditCard.vue' /* webpackChunkName: "components/popup-list-credit-card" */).then(c => wrapFunctional(c.default || c))
export const PopupListStoreBranch = () => import('../../components/home/checkout/PopupListStoreBranch.vue' /* webpackChunkName: "components/popup-list-store-branch" */).then(c => wrapFunctional(c.default || c))
export const PopupLoading = () => import('../../components/home/checkout/PopupLoading.vue' /* webpackChunkName: "components/popup-loading" */).then(c => wrapFunctional(c.default || c))
export const PopupOtherPayment = () => import('../../components/home/checkout/PopupOtherPayment.vue' /* webpackChunkName: "components/popup-other-payment" */).then(c => wrapFunctional(c.default || c))
export const PopupPaymentMethod = () => import('../../components/home/checkout/PopupPaymentMethod.vue' /* webpackChunkName: "components/popup-payment-method" */).then(c => wrapFunctional(c.default || c))
export const BannerFlashsale = () => import('../../components/home/flashsale/BannerFlashsale.vue' /* webpackChunkName: "components/banner-flashsale" */).then(c => wrapFunctional(c.default || c))
export const ListProductFlashsale = () => import('../../components/home/flashsale/ListProductFlashsale.vue' /* webpackChunkName: "components/list-product-flashsale" */).then(c => wrapFunctional(c.default || c))
export const TabFlashsale = () => import('../../components/home/flashsale/TabFlashsale.vue' /* webpackChunkName: "components/tab-flashsale" */).then(c => wrapFunctional(c.default || c))
export const TimeCountDownFlashsale = () => import('../../components/home/flashsale/TimeCountDownFlashsale.vue' /* webpackChunkName: "components/time-count-down-flashsale" */).then(c => wrapFunctional(c.default || c))
export const CardPartnerProfileHeader = () => import('../../components/home/partner/CardPartnerProfileHeader.vue' /* webpackChunkName: "components/card-partner-profile-header" */).then(c => wrapFunctional(c.default || c))
export const CatalogListPartner = () => import('../../components/home/partner/CatalogListPartner.vue' /* webpackChunkName: "components/catalog-list-partner" */).then(c => wrapFunctional(c.default || c))
export const PartnerProducts = () => import('../../components/home/partner/PartnerProducts.vue' /* webpackChunkName: "components/partner-products" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileBody = () => import('../../components/home/partner/PartnerProfileBody.vue' /* webpackChunkName: "components/partner-profile-body" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileBodyTabAllProduct = () => import('../../components/home/partner/PartnerProfileBodyTabAllProduct.vue' /* webpackChunkName: "components/partner-profile-body-tab-all-product" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileBodyTabHomepage = () => import('../../components/home/partner/PartnerProfileBodyTabHomepage.vue' /* webpackChunkName: "components/partner-profile-body-tab-homepage" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileHeader = () => import('../../components/home/partner/PartnerProfileHeader.vue' /* webpackChunkName: "components/partner-profile-header" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileHomepageFlashsale = () => import('../../components/home/partner/PartnerProfileHomepageFlashsale.vue' /* webpackChunkName: "components/partner-profile-homepage-flashsale" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileHomepageLastSeenProducts = () => import('../../components/home/partner/PartnerProfileHomepageLastSeenProducts.vue' /* webpackChunkName: "components/partner-profile-homepage-last-seen-products" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileHomepageLorikeet = () => import('../../components/home/partner/PartnerProfileHomepageLorikeet.vue' /* webpackChunkName: "components/partner-profile-homepage-lorikeet" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileHomepageShowcases = () => import('../../components/home/partner/PartnerProfileHomepageShowcases.vue' /* webpackChunkName: "components/partner-profile-homepage-showcases" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileHomepageSliderBanner = () => import('../../components/home/partner/PartnerProfileHomepageSliderBanner.vue' /* webpackChunkName: "components/partner-profile-homepage-slider-banner" */).then(c => wrapFunctional(c.default || c))
export const PartnerProfileHomepageVouchers = () => import('../../components/home/partner/PartnerProfileHomepageVouchers.vue' /* webpackChunkName: "components/partner-profile-homepage-vouchers" */).then(c => wrapFunctional(c.default || c))
export const FormPaymentConfirmation = () => import('../../components/home/payment-confirmation/FormPaymentConfirmation.vue' /* webpackChunkName: "components/form-payment-confirmation" */).then(c => wrapFunctional(c.default || c))
export const PaymentConfirmation = () => import('../../components/home/payment-confirmation/PaymentConfirmation.vue' /* webpackChunkName: "components/payment-confirmation" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCounterSuccess = () => import('../../components/home/payment-detail/CheckoutCounterSuccess.vue' /* webpackChunkName: "components/checkout-counter-success" */).then(c => wrapFunctional(c.default || c))
export const DetailCheckout = () => import('../../components/home/payment-detail/DetailCheckout.vue' /* webpackChunkName: "components/detail-checkout" */).then(c => wrapFunctional(c.default || c))
export const PaymentGuide = () => import('../../components/home/payment-detail/PaymentGuide.vue' /* webpackChunkName: "components/payment-guide" */).then(c => wrapFunctional(c.default || c))
export const PaymentHelpSection = () => import('../../components/home/payment-detail/PaymentHelpSection.vue' /* webpackChunkName: "components/payment-help-section" */).then(c => wrapFunctional(c.default || c))
export const PaymentInfo = () => import('../../components/home/payment-detail/PaymentInfo.vue' /* webpackChunkName: "components/payment-info" */).then(c => wrapFunctional(c.default || c))
export const FlashsaleProfileSection = () => import('../../components/home/profile/FlashsaleProfileSection.vue' /* webpackChunkName: "components/flashsale-profile-section" */).then(c => wrapFunctional(c.default || c))
export const HomePartnerNew = () => import('../../components/home/profile/HomePartnerNew.vue' /* webpackChunkName: "components/home-partner-new" */).then(c => wrapFunctional(c.default || c))
export const PopupListBranch = () => import('../../components/home/profile/PopupListBranch.vue' /* webpackChunkName: "components/popup-list-branch" */).then(c => wrapFunctional(c.default || c))
export const PopupListStore = () => import('../../components/home/profile/PopupListStore.vue' /* webpackChunkName: "components/popup-list-store" */).then(c => wrapFunctional(c.default || c))
export const ProductProfileSection = () => import('../../components/home/profile/ProductProfileSection.vue' /* webpackChunkName: "components/product-profile-section" */).then(c => wrapFunctional(c.default || c))
export const SliderBrandSection = () => import('../../components/home/profile/SliderBrandSection.vue' /* webpackChunkName: "components/slider-brand-section" */).then(c => wrapFunctional(c.default || c))
export const FilterProfileMobile = () => import('../../components/home/profile/filterProfileMobile.vue' /* webpackChunkName: "components/filter-profile-mobile" */).then(c => wrapFunctional(c.default || c))
export const BannerEventSaleSection = () => import('../../components/home/sale/BannerEventSaleSection.vue' /* webpackChunkName: "components/banner-event-sale-section" */).then(c => wrapFunctional(c.default || c))
export const BannerVoucher = () => import('../../components/home/sale/BannerVoucher.vue' /* webpackChunkName: "components/banner-voucher" */).then(c => wrapFunctional(c.default || c))
export const BrandSaleSection = () => import('../../components/home/sale/BrandSaleSection.vue' /* webpackChunkName: "components/brand-sale-section" */).then(c => wrapFunctional(c.default || c))
export const EventSaleOnNotSearch = () => import('../../components/home/sale/EventSaleOnNotSearch.vue' /* webpackChunkName: "components/event-sale-on-not-search" */).then(c => wrapFunctional(c.default || c))
export const EventSaleOnSearch = () => import('../../components/home/sale/EventSaleOnSearch.vue' /* webpackChunkName: "components/event-sale-on-search" */).then(c => wrapFunctional(c.default || c))
export const EventSaleProducts = () => import('../../components/home/sale/EventSaleProducts.vue' /* webpackChunkName: "components/event-sale-products" */).then(c => wrapFunctional(c.default || c))
export const SectionSale = () => import('../../components/home/sale/SectionSale.vue' /* webpackChunkName: "components/section-sale" */).then(c => wrapFunctional(c.default || c))
export const SectionSalePreview = () => import('../../components/home/sale/SectionSalePreview.vue' /* webpackChunkName: "components/section-sale-preview" */).then(c => wrapFunctional(c.default || c))
export const SectionTemplate = () => import('../../components/home/sale/SectionTemplate.vue' /* webpackChunkName: "components/section-template" */).then(c => wrapFunctional(c.default || c))
export const AllProduct = () => import('../../components/home/users/AllProduct.vue' /* webpackChunkName: "components/all-product" */).then(c => wrapFunctional(c.default || c))
export const CardUserProfile = () => import('../../components/home/users/CardUserProfile.vue' /* webpackChunkName: "components/card-user-profile" */).then(c => wrapFunctional(c.default || c))
export const TopBrands = () => import('../../components/home/users/TopBrands.vue' /* webpackChunkName: "components/top-brands" */).then(c => wrapFunctional(c.default || c))
export const TopProducts = () => import('../../components/home/users/TopProducts.vue' /* webpackChunkName: "components/top-products" */).then(c => wrapFunctional(c.default || c))
export const UserProfileBanner = () => import('../../components/home/users/UserProfileBanner.vue' /* webpackChunkName: "components/user-profile-banner" */).then(c => wrapFunctional(c.default || c))
export const CardCommissionStat = () => import('../../components/account/affiliate/commission/CardCommissionStat.vue' /* webpackChunkName: "components/card-commission-stat" */).then(c => wrapFunctional(c.default || c))
export const CommissionHistoryItem = () => import('../../components/account/affiliate/commission/CommissionHistoryItem.vue' /* webpackChunkName: "components/commission-history-item" */).then(c => wrapFunctional(c.default || c))
export const CommissionWithdrawItem = () => import('../../components/account/affiliate/commission/CommissionWithdrawItem.vue' /* webpackChunkName: "components/commission-withdraw-item" */).then(c => wrapFunctional(c.default || c))
export const ContentModalDetailWithdrawCommissionTypeIn = () => import('../../components/account/affiliate/commission/ContentModalDetailWithdrawCommissionTypeIn.vue' /* webpackChunkName: "components/content-modal-detail-withdraw-commission-type-in" */).then(c => wrapFunctional(c.default || c))
export const ContentModalDetailWithdrawCommissionTypeOut = () => import('../../components/account/affiliate/commission/ContentModalDetailWithdrawCommissionTypeOut.vue' /* webpackChunkName: "components/content-modal-detail-withdraw-commission-type-out" */).then(c => wrapFunctional(c.default || c))
export const ListCommissionHistoriesByMonth = () => import('../../components/account/affiliate/commission/ListCommissionHistoriesByMonth.vue' /* webpackChunkName: "components/list-commission-histories-by-month" */).then(c => wrapFunctional(c.default || c))
export const ModalChooseWithdrawCommission = () => import('../../components/account/affiliate/commission/ModalChooseWithdrawCommission.vue' /* webpackChunkName: "components/modal-choose-withdraw-commission" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateCommissionWithdrawSubmission = () => import('../../components/account/affiliate/commission/ModalCreateCommissionWithdrawSubmission.vue' /* webpackChunkName: "components/modal-create-commission-withdraw-submission" */).then(c => wrapFunctional(c.default || c))
export const ModalDetailWithdrawCommission = () => import('../../components/account/affiliate/commission/ModalDetailWithdrawCommission.vue' /* webpackChunkName: "components/modal-detail-withdraw-commission" */).then(c => wrapFunctional(c.default || c))
export const WithdrawCommissionItem = () => import('../../components/account/affiliate/commission/WithdrawCommissionItem.vue' /* webpackChunkName: "components/withdraw-commission-item" */).then(c => wrapFunctional(c.default || c))
export const CardStatsAffiliate = () => import('../../components/account/affiliate/dashboard/CardStatsAffiliate.vue' /* webpackChunkName: "components/card-stats-affiliate" */).then(c => wrapFunctional(c.default || c))
export const PopularProducts = () => import('../../components/account/affiliate/dashboard/PopularProducts.vue' /* webpackChunkName: "components/popular-products" */).then(c => wrapFunctional(c.default || c))
export const PopularProductsAffiliate = () => import('../../components/account/affiliate/dashboard/PopularProductsAffiliate.vue' /* webpackChunkName: "components/popular-products-affiliate" */).then(c => wrapFunctional(c.default || c))
export const ProductRecommendationAffiliate = () => import('../../components/account/affiliate/dashboard/ProductRecommendationAffiliate.vue' /* webpackChunkName: "components/product-recommendation-affiliate" */).then(c => wrapFunctional(c.default || c))
export const WeeklyCommissionHistory = () => import('../../components/account/affiliate/dashboard/WeeklyCommissionHistory.vue' /* webpackChunkName: "components/weekly-commission-history" */).then(c => wrapFunctional(c.default || c))
export const ListAffiliateProducts = () => import('../../components/account/affiliate/product-list/ListAffiliateProducts.vue' /* webpackChunkName: "components/list-affiliate-products" */).then(c => wrapFunctional(c.default || c))
export const ListMyOrder = () => import('../../components/account/shop/my-order/ListMyOrder.vue' /* webpackChunkName: "components/list-my-order" */).then(c => wrapFunctional(c.default || c))
export const PopupAddMore = () => import('../../components/account/shop/my-order/PopupAddMore.vue' /* webpackChunkName: "components/popup-add-more" */).then(c => wrapFunctional(c.default || c))
export const EmptyReview = () => import('../../components/account/shop/order-review/EmptyReview.vue' /* webpackChunkName: "components/empty-review" */).then(c => wrapFunctional(c.default || c))
export const ListOrderReview = () => import('../../components/account/shop/order-review/ListOrderReview.vue' /* webpackChunkName: "components/list-order-review" */).then(c => wrapFunctional(c.default || c))
export const ModalImageReview = () => import('../../components/account/shop/order-review/ModalImageReview.vue' /* webpackChunkName: "components/modal-image-review" */).then(c => wrapFunctional(c.default || c))
export const ModalRebuyProduct = () => import('../../components/account/shop/order-review/ModalRebuyProduct.vue' /* webpackChunkName: "components/modal-rebuy-product" */).then(c => wrapFunctional(c.default || c))
export const WishlistCard = () => import('../../components/account/shop/wishlist/WishlistCard.vue' /* webpackChunkName: "components/wishlist-card" */).then(c => wrapFunctional(c.default || c))
export const WishlistSection = () => import('../../components/account/shop/wishlist/WishlistSection.vue' /* webpackChunkName: "components/wishlist-section" */).then(c => wrapFunctional(c.default || c))
export const HeaderBackup = () => import('../../components/home/base/backup/HeaderBackup.vue' /* webpackChunkName: "components/header-backup" */).then(c => wrapFunctional(c.default || c))
export const MegamenuCategoryBackup = () => import('../../components/home/base/backup/MegamenuCategoryBackup.vue' /* webpackChunkName: "components/megamenu-category-backup" */).then(c => wrapFunctional(c.default || c))
export const NavbarBackup = () => import('../../components/home/base/backup/NavbarBackup.vue' /* webpackChunkName: "components/navbar-backup" */).then(c => wrapFunctional(c.default || c))
export const CartCardProduct = () => import('../../components/home/cart/partials/CartCardProduct.vue' /* webpackChunkName: "components/cart-card-product" */).then(c => wrapFunctional(c.default || c))
export const CartCardTitle = () => import('../../components/home/cart/partials/CartCardTitle.vue' /* webpackChunkName: "components/cart-card-title" */).then(c => wrapFunctional(c.default || c))
export const VoucherItem = () => import('../../components/home/cart/partials/VoucherItem.vue' /* webpackChunkName: "components/voucher-item" */).then(c => wrapFunctional(c.default || c))
export const CartGroupItemBranch = () => import('../../components/home/checkout/partials/CartGroupItemBranch.vue' /* webpackChunkName: "components/cart-group-item-branch" */).then(c => wrapFunctional(c.default || c))
export const CartGroupProductItem = () => import('../../components/home/checkout/partials/CartGroupProductItem.vue' /* webpackChunkName: "components/cart-group-product-item" */).then(c => wrapFunctional(c.default || c))
export const CartGroupShippingPickup = () => import('../../components/home/checkout/partials/CartGroupShippingPickup.vue' /* webpackChunkName: "components/cart-group-shipping-pickup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartGroupItem = () => import('../../components/home/checkout/partials/CheckoutCartGroupItem.vue' /* webpackChunkName: "components/checkout-cart-group-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentMethodItem = () => import('../../components/home/checkout/partials/CheckoutPaymentMethodItem.vue' /* webpackChunkName: "components/checkout-payment-method-item" */).then(c => wrapFunctional(c.default || c))
export const CreditCardSelect = () => import('../../components/home/checkout/partials/CreditCardSelect.vue' /* webpackChunkName: "components/credit-card-select" */).then(c => wrapFunctional(c.default || c))
export const FormPopupAddress = () => import('../../components/home/checkout/partials/FormPopupAddress.vue' /* webpackChunkName: "components/form-popup-address" */).then(c => wrapFunctional(c.default || c))
export const Popup3DS = () => import('../../components/home/checkout/partials/Popup3DS.vue' /* webpackChunkName: "components/popup3-d-s" */).then(c => wrapFunctional(c.default || c))
export const PopupCVVNew = () => import('../../components/home/checkout/partials/PopupCVVNew.vue' /* webpackChunkName: "components/popup-c-v-v-new" */).then(c => wrapFunctional(c.default || c))
export const PopupCardAddressItem = () => import('../../components/home/checkout/partials/PopupCardAddressItem.vue' /* webpackChunkName: "components/popup-card-address-item" */).then(c => wrapFunctional(c.default || c))
export const PopupCheckoutLoading = () => import('../../components/home/checkout/partials/PopupCheckoutLoading.vue' /* webpackChunkName: "components/popup-checkout-loading" */).then(c => wrapFunctional(c.default || c))
export const PopupFormCreditCard = () => import('../../components/home/checkout/partials/PopupFormCreditCard.vue' /* webpackChunkName: "components/popup-form-credit-card" */).then(c => wrapFunctional(c.default || c))
export const PopupListAddress = () => import('../../components/home/checkout/partials/PopupListAddress.vue' /* webpackChunkName: "components/popup-list-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartGroupSkeleton = () => import('../../components/home/checkout/skeleton/CheckoutCartGroupSkeleton.vue' /* webpackChunkName: "components/checkout-cart-group-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentMethodSkeleton = () => import('../../components/home/checkout/skeleton/CheckoutPaymentMethodSkeleton.vue' /* webpackChunkName: "components/checkout-payment-method-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProductsFlashsale = () => import('../../components/home/flashsale/partials/ProductsFlashsale.vue' /* webpackChunkName: "components/products-flashsale" */).then(c => wrapFunctional(c.default || c))
export const CatalogListProduct = () => import('../../components/home/products/catalogue/CatalogListProduct.vue' /* webpackChunkName: "components/catalog-list-product" */).then(c => wrapFunctional(c.default || c))
export const FilterSection = () => import('../../components/home/products/catalogue/FilterSection.vue' /* webpackChunkName: "components/filter-section" */).then(c => wrapFunctional(c.default || c))
export const PopupFilter = () => import('../../components/home/products/catalogue/PopupFilter.vue' /* webpackChunkName: "components/popup-filter" */).then(c => wrapFunctional(c.default || c))
export const RelatedSection = () => import('../../components/home/products/catalogue/RelatedSection.vue' /* webpackChunkName: "components/related-section" */).then(c => wrapFunctional(c.default || c))
export const SearchResultProducts = () => import('../../components/home/products/catalogue/SearchResultProducts.vue' /* webpackChunkName: "components/search-result-products" */).then(c => wrapFunctional(c.default || c))
export const SortBySection = () => import('../../components/home/products/catalogue/SortBySection.vue' /* webpackChunkName: "components/sort-by-section" */).then(c => wrapFunctional(c.default || c))
export const AddToCartBottom = () => import('../../components/home/products/detail/AddToCartBottom.vue' /* webpackChunkName: "components/add-to-cart-bottom" */).then(c => wrapFunctional(c.default || c))
export const DetailProductSection = () => import('../../components/home/products/detail/DetailProductSection.vue' /* webpackChunkName: "components/detail-product-section" */).then(c => wrapFunctional(c.default || c))
export const FulfilledSection = () => import('../../components/home/products/detail/FulfilledSection.vue' /* webpackChunkName: "components/fulfilled-section" */).then(c => wrapFunctional(c.default || c))
export const InformationProductSection = () => import('../../components/home/products/detail/InformationProductSection.vue' /* webpackChunkName: "components/information-product-section" */).then(c => wrapFunctional(c.default || c))
export const LorikeetSection = () => import('../../components/home/products/detail/LorikeetSection.vue' /* webpackChunkName: "components/lorikeet-section" */).then(c => wrapFunctional(c.default || c))
export const ModalShareProduct = () => import('../../components/home/products/detail/ModalShareProduct.vue' /* webpackChunkName: "components/modal-share-product" */).then(c => wrapFunctional(c.default || c))
export const NearestStoreItem = () => import('../../components/home/products/detail/NearestStoreItem.vue' /* webpackChunkName: "components/nearest-store-item" */).then(c => wrapFunctional(c.default || c))
export const NearestStoreSection = () => import('../../components/home/products/detail/NearestStoreSection.vue' /* webpackChunkName: "components/nearest-store-section" */).then(c => wrapFunctional(c.default || c))
export const OtherProduct = () => import('../../components/home/products/detail/OtherProduct.vue' /* webpackChunkName: "components/other-product" */).then(c => wrapFunctional(c.default || c))
export const ProductImagesFooter = () => import('../../components/home/products/detail/ProductImagesFooter.vue' /* webpackChunkName: "components/product-images-footer" */).then(c => wrapFunctional(c.default || c))
export const ReviewSection = () => import('../../components/home/products/detail/ReviewSection.vue' /* webpackChunkName: "components/review-section" */).then(c => wrapFunctional(c.default || c))
export const SimilarProduct = () => import('../../components/home/products/detail/SimilarProduct.vue' /* webpackChunkName: "components/similar-product" */).then(c => wrapFunctional(c.default || c))
export const StoreSection = () => import('../../components/home/products/detail/StoreSection.vue' /* webpackChunkName: "components/store-section" */).then(c => wrapFunctional(c.default || c))
export const VideoProductSection = () => import('../../components/home/products/detail/VideoProductSection.vue' /* webpackChunkName: "components/video-product-section" */).then(c => wrapFunctional(c.default || c))
export const FormRatingReview = () => import('../../components/home/review/add/FormRatingReview.vue' /* webpackChunkName: "components/form-rating-review" */).then(c => wrapFunctional(c.default || c))
export const CommissionWithdrawalSubmissionHistoryItem = () => import('../../components/account/affiliate/commission/withdrawal/CommissionWithdrawalSubmissionHistoryItem.vue' /* webpackChunkName: "components/commission-withdrawal-submission-history-item" */).then(c => wrapFunctional(c.default || c))
export const ListCommissionWithdrawalSubmissionHistoriesByMonth = () => import('../../components/account/affiliate/commission/withdrawal/ListCommissionWithdrawalSubmissionHistoriesByMonth.vue' /* webpackChunkName: "components/list-commission-withdrawal-submission-histories-by-month" */).then(c => wrapFunctional(c.default || c))
export const ModalDetailCommissionWithdrawSubmissionHistory = () => import('../../components/account/affiliate/commission/withdrawal/ModalDetailCommissionWithdrawSubmissionHistory.vue' /* webpackChunkName: "components/modal-detail-commission-withdraw-submission-history" */).then(c => wrapFunctional(c.default || c))
export const StepperAffiliateCommissionWithdrawalHistory = () => import('../../components/account/affiliate/commission/withdrawal/StepperAffiliateCommissionWithdrawalHistory.vue' /* webpackChunkName: "components/stepper-affiliate-commission-withdrawal-history" */).then(c => wrapFunctional(c.default || c))
export const ListUnaffiliatedProducts = () => import('../../components/account/affiliate/product-list/add-product/ListUnaffiliatedProducts.vue' /* webpackChunkName: "components/list-unaffiliated-products" */).then(c => wrapFunctional(c.default || c))
export const DetailOrderPayment = () => import('../../components/account/shop/my-order/detail-order/DetailOrderPayment.vue' /* webpackChunkName: "components/detail-order-payment" */).then(c => wrapFunctional(c.default || c))
export const DetailOrderStatus = () => import('../../components/account/shop/my-order/detail-order/DetailOrderStatus.vue' /* webpackChunkName: "components/detail-order-status" */).then(c => wrapFunctional(c.default || c))
export const DetailOrderStuff = () => import('../../components/account/shop/my-order/detail-order/DetailOrderStuff.vue' /* webpackChunkName: "components/detail-order-stuff" */).then(c => wrapFunctional(c.default || c))
export const DetailOrderTime = () => import('../../components/account/shop/my-order/detail-order/DetailOrderTime.vue' /* webpackChunkName: "components/detail-order-time" */).then(c => wrapFunctional(c.default || c))
export const DetailOrderUser = () => import('../../components/account/shop/my-order/detail-order/DetailOrderUser.vue' /* webpackChunkName: "components/detail-order-user" */).then(c => wrapFunctional(c.default || c))
export const ModalCancelOrder = () => import('../../components/account/shop/my-order/detail-order/ModalCancelOrder.vue' /* webpackChunkName: "components/modal-cancel-order" */).then(c => wrapFunctional(c.default || c))
export const ModalTrackingOrder = () => import('../../components/account/shop/my-order/detail-order/ModalTrackingOrder.vue' /* webpackChunkName: "components/modal-tracking-order" */).then(c => wrapFunctional(c.default || c))
export const OrderStepper = () => import('../../components/account/shop/my-order/detail-order/OrderStepper.vue' /* webpackChunkName: "components/order-stepper" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
