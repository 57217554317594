export const showcaseTypes = {
  flashSale: 'flash_sale',
  eventSale: 'event_sale',
  presale: 'pre_sale',
  clearanceSale: 'clearance_sale',
  sale: 'sale',
  product: 'product'
}

export const showcaseFileNames = {
  showcaseImage: 'showcase_image'
}

export const showcaseSlugs = {
  allProduct: 'semua-produk',
  flashSale: 'flash-sale'
}

export const getShowcaseIconByType = (type) => {
  switch (type) {
    case showcaseTypes.flashSale:
      return '/img/icon/sale/icon-flashsale-square.svg'
    case showcaseTypes.eventSale:
      return '/img/icon/sale/icon-event-sale-square.svg'
    case showcaseTypes.clearanceSale:
      return '/img/icon/sale/icon-clearance-sale-square.svg'
    case showcaseTypes.presale:
      return '/img/icon/sale/icon-presale-square.svg'
    default:
      return ''
  }
}
