import FeatureServices from '@/services/Feature'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'

export const state = () => ({
  panintiPointActive: false
})

export const mutations = {
  setPanintiPointActive (state, payload) {
    if (isArrayNotNullOrEmpty(payload) && payload[0].key === 'paninti_point.active') {
      state.panintiPointActive = payload[0].value
    }
  }
}

export const actions = {
  getPanintiPointActive ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FeatureServices.getFeatures({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      },
      payload
    )
        .then((response) => {
          commit('setPanintiPointActive', response.data.data.features)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
