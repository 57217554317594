import FilterService from '@/services/Product/filter'
import ProductService from '@/services/Product'
import PartnerService from '@/services/Partner'

export const state = () => ({
  searchResultSections: {
    all: {
      slug: 'all',
      label: 'Semua Produk',
      getProductMethodName: 'getHomeProducts'
    },
    newProducts: {
      slug: 'new-products',
      label: 'Produk Terbaru',
      getProductMethodName: 'getNewProducts'
    },
    todayPromotion: {
      slug: 'today-promotion',
      label: 'Promo Hari Ini',
      getProductMethodName: 'getTodayPromotionProducts'
    },
    onSaleBrandPartner: {
      slug: 'on-sale-brand-partner',
      label: 'Lagi Diskon Dari Brand Partner',
      getProductMethodName: 'getOnSaleBrandPartnerProducts'
    },
    onSaleStorePartner: {
      slug: 'on-sale-store-partner',
      label: 'Lagi Diskon Dari Store Partner',
      getProductMethodName: 'getOnSaleStorePartnerProducts'
    }
  }
})

export const actions = {
  getProductsSideFilter ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      FilterService.getFilterPage(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getPartners ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      PartnerService.getPartners(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getProducts ({ commit, state }, payload) {
    const section = Object.values(state.searchResultSections).find((section) => {
      return section.slug === payload.section
    })
    if (!section) {
      return Promise.reject(new Error('Invalid section'))
    }

    return new Promise((resolve, reject) => {
      ProductService[section.getProductMethodName](
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
