export const defaultPaginationAttributes = () => ({
  loading: true,
  paginated: true,
  perPage: 10,
  page: 0,
  total: 0,
  totalPage: 1,
  data: []
})

export const defaultNotPaginationAttributes = () => ({
  loading: true,
  paginated: false,
  total: 0,
  data: []
})

export const assignResponseToDataModelData = (modelData, responseData, replaceData = true) => {
  Object.assign(modelData, {
    perPage: Number(responseData.per_page) || defaultPaginationAttributes.perPage,
    page: responseData.current_page,
    total: responseData.total,
    totalPage: responseData.last_page
  })

  if (replaceData) {
    Object.assign(modelData, {
      data: responseData.data
    })
  } else {
    responseData.data.forEach((item) => {
      modelData.data.push(item)
    })
  }
}

export const resetPaginationData = (modelData, additionalPayload = {}) => {
  Object.assign(modelData, {
    ...defaultPaginationAttributes(),
    ...additionalPayload
  })
  modelData.data = []
}

export const resetNotPaginationData = (modelData, additionalPayload = {}) => {
  Object.assign(modelData, {
    ...defaultNotPaginationAttributes(),
    ...additionalPayload
  })
  modelData.data = []
}

export const assignResponseToDataModelDataNotPaginated = (modelData, responseData, replaceData = true) => {
  Object.assign(modelData, {
    total: responseData.length || 0
  })

  if (replaceData) {
    Object.assign(modelData, {
      data: responseData
    })
  } else {
    responseData.forEach((item) => {
      modelData.data.push(item)
    })
  }
}

export const isContentVisible = (content) => {
  return (
    content.loading === true ||
    (content.loading === false &&
      Array.isArray(content.data) &&
      content.data.length > 0)
  )
}
