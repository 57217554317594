import { apiClient, getHeaders } from '@/services/axiosConfig'

export default {
  getCarts (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/carts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCartSummary (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/carts/summary',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCartsNavbar (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/carts/navbar',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  addCart (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/carts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  addBulkCart (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/carts/bulk',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  updateCart (auth, payload) {
    return apiClient({
      method: 'patch',
      url: `/v1/general/carts/${payload.id}/quantity`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  deleteCart (auth, payload) {
    return apiClient({
      method: 'delete',
      url: '/v1/general/carts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCartAvailability (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/carts/check-availability',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
