export const sortArrayByKey = (array, key) => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
}

export const isArrayNotNullOrEmpty = (array) => {
  return !!(array && array.length)
}

export const pushOrRemoveIfExists = (array, item) => {
  const index = array.indexOf(item)

  if (index !== -1) {
    array.splice(index, 1)
  } else {
    array.push(item)
  }

  return array
}

export const pushIfArrayObjectNotExists = (array, key, value) => {
  // Find the index of the object with the given key-value pair
  const index = array.findIndex(obj => obj[key] === value[key])

  // If not found, push a new object with the value pair
  if (index === -1) {
    array.push(value)
  }

  return array
}

export const updateArray = (existingArray, insertedArray, key) => {
  // handle object
  if (key) {
    const updatedArray = existingArray.filter(item =>
      insertedArray.some(insertedItem => insertedItem[key] === item[key])
    )

    // Add new elements from insertedArray if not already in updatedArray
    insertedArray.forEach((insertedItem) => {
      const exists = updatedArray.some(item => item[key] === insertedItem[key])
      if (!exists) {
        updatedArray.push(insertedItem)
      }
    })

    return updatedArray
  } else {
    // Handle simple arrays (primitives)
    const updatedArray = existingArray.filter(item => insertedArray.includes(item))

    // Add new values from insertedArray if not already in updatedArray
    insertedArray.forEach((insertedItem) => {
      if (!updatedArray.includes(insertedItem)) {
        updatedArray.push(insertedItem)
      }
    })

    return updatedArray
  }
}
