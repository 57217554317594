const ALPHANUMERIC_CHARS =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const ALL_CHARS = ALPHANUMERIC_CHARS + '!@#$%^&*()_+?><:{}[]'

export const generateRandomString = (limit, type = 'all') => {
  const chars = type === 'alphanumeric' ? ALPHANUMERIC_CHARS : ALL_CHARS
  let result = ''

  for (let i = 0; i < limit; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return result
}

export const handleFormatRupiah = (amount = 0, prefix = 'Rp') => {
  // Remove any non-digit characters except comma
  const numberString = amount.toString().replace(/[^,\d.]/g, '')
  const [integerPart, fractionalPart] = numberString.split('.')

  // Calculate the initial segment length
  const initialSegmentLength = integerPart.length % 3

  // Format the integer part with dots as thousand separators
  let rupiah = integerPart.substr(0, initialSegmentLength)
  const thousands = integerPart.substr(initialSegmentLength).match(/\d{3}/gi)

  if (thousands) {
    const separator = initialSegmentLength ? '.' : ''
    rupiah += separator + thousands.join('.')
  }

  // Include the fractional part if it exists
  rupiah = fractionalPart !== undefined ? `${rupiah},${fractionalPart}` : rupiah

  // Add the prefix if provided
  return prefix ? `${prefix}${rupiah}` : rupiah
}

export const formatRupiah = (amount = 0, prefix = 'Rp') => {
  if (!amount || (typeof value === 'number' && amount < 0)) {
    amount = 0
  }

  const parts = amount.toString().split('-')
  return parts
    .map((part) => {
      return handleFormatRupiah(part, prefix)
    })
    .join(' - ')
}

export const formatPriceWithoutPrefix = (amount = 0) => {
  // Remove any non-digit characters except comma
  const numberString = amount.toString().replace(/[^,\d]/g, '')
  const [integerPart, fractionalPart] = numberString.split(',')

  // Calculate the initial segment length
  const initialSegmentLength = integerPart.length % 3

  // Format the integer part with dots as thousand separators
  let rupiah = integerPart.substr(0, initialSegmentLength)
  const thousands = integerPart.substr(initialSegmentLength).match(/\d{3}/gi)

  if (thousands) {
    const separator = initialSegmentLength ? '.' : ''
    rupiah += separator + thousands.join('.')
  }

  // Include the fractional part if it exists
  rupiah = fractionalPart !== undefined ? `${rupiah},${fractionalPart}` : rupiah

  // Add the prefix if provided
  return rupiah
}

export const isUuid = (param) => {
  // Regular expression to match UUID v4 format
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regex.test(param);
}

export const limitNumber = (value, limit = 10000, suffix = '+') => {
  if (Number(value) > limit) {
    return (limit - 1) + suffix;
  }

  return value.toString();
}

export const slugtify = (text) => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const ucwords = (str) => {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase()
  })
}

export const maskNumber = (number, visibleDigits = 4, maskChar = '*', cropMask = -1) => {
  const numStr = number.toString()
  let maskedPart = maskChar.repeat(numStr.length - visibleDigits)
  const visiblePart = numStr.slice(-visibleDigits)

  if (cropMask > -1) {
    maskedPart = maskedPart.substring(0, cropMask)
  }

  return maskedPart + visiblePart
}
