import PartnerService from '@/services/Partner'
import ProductService from '@/services/Product'
import VoucherService from '@/services/Voucher'
import FilterServices from '@/services/Product/filter'
import FlashsaleService from '@/services/Flashsale'

import {
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes
} from '@/utils/helpers/pagination-helper'
import { slugtify } from '@/utils/helpers/string-helper'
import { showcaseSlugs, showcaseTypes } from '@/utils/helpers/showcase-helper'
import { pushIfArrayObjectNotExists } from '@/utils/helpers/array-helper'

export const state = () => ({
  partnerProfileTabItems: {
    homepage: {
      name: 'homepage',
      label: 'Beranda'
    },
    allProduct: {
      name: 'allProduct',
      label: 'Semua Produk'
    }
  },

  partnerProfileHomepageSections: {
    vouchers: {
      name: 'vouchers'
    },
    lastSeenProducts: {
      name: 'lastSeenProducts'
    },
    showcase: {
      name: 'showcase'
    },
    bestSellerProducts: {
      name: 'bestSellerProducts'
    },
    recommendationProducts: {
      name: 'recommendationProducts'
    }
  },

  sideFilterShowcases: [
    {
      id: null,
      event_sale_session_id: null,
      name: 'Semua Produk',
      slug: showcaseSlugs.allProduct,
      type: showcaseTypes.product
    }
  ],

  partner: null,
  partnerShowCases: {
    ...defaultNotPaginationAttributes()
  }
})

export const mutations = {
  SET_PARTNER (state, payload) {
    state.partner = payload
  },

  SET_PARTNER_SHOWCASES_LOADING (state, payload) {
    state.partnerShowCases.loading = payload
  },

  SET_PARTNER_SHOWCASES (state, payload) {
    assignResponseToDataModelDataNotPaginated(state.partnerShowCases, payload)
  },

  ADD_PARTNER_SIDE_FILTER_SHOWCASES (state, payload) {
    if (Array.isArray(payload)) {
      payload.forEach((showcase) => {
        pushIfArrayObjectNotExists(state.sideFilterShowcases, 'slug', showcase)
      })
    }
  }
}

export const actions = {
  showPartner ({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      PartnerService.showPartner(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        id
      )
        .then((response) => {
          const partner = response.data.data.store
          partner.lorikeets = partner.lorikeets.map(lorikeet => ({
            ...lorikeet,
            exists: true
          }))

          commit('SET_PARTNER', partner)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getShowcasePartner ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_PARTNER_SHOWCASES_LOADING', true)

      PartnerService.getShowcasePartner(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          let responseShowcases = Array.from(response.data.data.showcases)
          responseShowcases = responseShowcases.map(showcase => ({
            ...showcase,
            slug: !showcase.slug ? slugtify(showcase.name) : showcase.slug
          }))

          commit('SET_PARTNER_SHOWCASES', responseShowcases)
          commit('ADD_PARTNER_SIDE_FILTER_SHOWCASES', responseShowcases)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          commit('SET_PARTNER_SHOWCASES_LOADING', false)
        })
    })
  },

  getOngoingSaleSessions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FlashsaleService.getOngoingSession(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getRelatedPartners ({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      PartnerService.getRelatedPartners(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        id,
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getVouchers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      VoucherService.getVouchers(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getLastSeenProducts ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      ProductService.getLastSeenProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getBestSellerProducts ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      ProductService.getBestSellerProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getRecommendationProducts ({ commit }, { id, payload }) {
    return new Promise((resolve, reject) => {
      PartnerService.getProductRecommendations(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        id,
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getProductsSideFilter ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      FilterServices.getFilterPage(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductService.getHomeProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getOnSaleProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductService.getProductsSale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getEventSaleProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FlashsaleService.getProductSession(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
