import { apiClient, getHeaders } from '@/services/axiosConfig'

export default {
  getFeatures (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/features',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
