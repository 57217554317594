import { apiClient, getHeaders } from '@/services/axiosConfig'

export default {
  getScheduleStoreBranch (auth, idBranch, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/store-branch-schedules/${idBranch}/current-schedules`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getStoreBranch (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/store-branches/checkout',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload.payload
    })
  },
  getProductStoreBranch (auth, branchId, payload) {
    return apiClient({
      method: 'post',
      url: `/v1/general/store/branches/${branchId}/product-availabilities`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload.payload
    })
  },
  getShippingCourier (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/checkout/shipping-couriers',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getListPayment (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/payment-methods',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getItemAvailability (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/checkout/availability',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCheckoutAvailability (auth, payload) {
    console.log('check-availability')
    return apiClient({
      method: 'post',
      url: '/v1/general/checkout/check-availability',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
