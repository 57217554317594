import Voucher from '@/services/Voucher/'
import mixin from '@/mixins'

import { isArrayNotNullOrEmpty, updateArray } from '@/utils/helpers/array-helper'
export const state = () => ({
  vouchers: [],
  vouchersDiscount: {}, // { paginated, nextPage, total, data }
  vouchersShipping: {}, // { paginated, nextPage, total, data }
  voucher: {},
  voucherUsed: [],
  showDetail: false,
  checkVoucher: false,
  pageVoucher: 1,
  totalPageVoucher: 1,
  popupVoucherModal: false
})

export const mutations = {
  setVouchers (state, payload) {
    if (Number(payload.page) === 1) {
      state.vouchers = payload.data
    } else {
      state.vouchers = state.vouchers.concat(payload.data)
    }
    state.pageVoucher++
  },
  setVouchersAvailable (state, payload) {
    // check if group store_id has avail or not
    const checkIndex = state.vouchers.findIndex((voucher) => {
      return voucher.store_id === payload.store_id
    })
    if (checkIndex > -1) {
      const selectedStoreVoucher = state.vouchers[checkIndex]
      if (payload.current_page !== 1) {
        selectedStoreVoucher.data = isArrayNotNullOrEmpty(payload.data) ? updateArray(selectedStoreVoucher.data, payload.data, 'id') : state.vouchers[checkIndex].data
      } else {
        selectedStoreVoucher.data = updateArray(selectedStoreVoucher.data, payload.data, 'id')
      }
      selectedStoreVoucher.page = payload.page
      selectedStoreVoucher.paginated = payload.paginated
      selectedStoreVoucher.next_page_url = payload.paginated ? payload.next_page_url : null

      state.vouchers.splice(checkIndex, 1, selectedStoreVoucher)
    } else {
      state.vouchers.push({
        ...payload,
        seeMore: false
      })
    }
  },
  editVouchersAvailable (state, payload) {
    const { storeId, voucherId, fields } = payload

    const voucherStoreIdx = state.vouchers.findIndex((voucher) => {
      return voucher.store_id === storeId
    })

    if (voucherStoreIdx === -1) {
      return
    }

    // if voucher id exist
    const voucherDataIdx = state.vouchers[voucherStoreIdx].data.findIndex((dt) => {
      return dt.id === voucherId
    })

    fields.forEach((field) => {
      const { name, value } = field

      // update data item voucher
      if (voucherDataIdx > -1) {
        state.vouchers[voucherStoreIdx].data[voucherDataIdx][name] = value
      } else {
        state.vouchers[voucherStoreIdx][name] = value
      }
    })
  },
  pushOrUpdateVouchersAvailable (state, payload) {
    const { storeId, voucherId, voucherData, voucherTotalDiscount } = payload

    const voucherStoreIdx = state.vouchers.findIndex((voucher) => {
      return voucher.store_id === storeId
    })

    if (voucherStoreIdx === -1) {
      return
    }

    // if voucher id exist
    const voucherDataIdx = state.vouchers[voucherStoreIdx].data.findIndex((dt) => {
      return dt.id === voucherId
    })

    if (voucherDataIdx > -1) {
      state.vouchers[voucherStoreIdx].data.splice(voucherDataIdx, 1, {
        ...voucherData,
        total_discount: voucherTotalDiscount
      })
    } else {
      state.vouchers[voucherStoreIdx].data.push({
        ...voucherData,
        total_discount: voucherTotalDiscount
      })
    }
  },
  removeVouchersAvailableByStore (state, payload) {
    const { storeId } = payload

    const voucherStoreIdx = state.vouchers.findIndex((voucher) => {
      return voucher.store_id === storeId
    })

    if (voucherStoreIdx === -1) {
      return
    }

    state.vouchers.splice(voucherStoreIdx, 1)
  },
  setVouchersByType (state, payload) {
    const { paginated, type, nextPage, total } = payload
    let { data } = payload

    data = data.map((dt) => {
      const usedIdx = state.voucherUsed.findIndex((used) => {
        return used.id === dt.id
      })
      let dataObj = {
        ...dt,
        active: true,
        selectType: 'choose'
      }

      if (usedIdx > -1) {
        dataObj = Object.assign(dataObj, {
          available: state.voucherUsed[usedIdx].available,
          total_discount: state.voucherUsed[usedIdx].total_discount,
          selectType: state.voucherUsed[usedIdx].selectType,
          minimum_amount: state.voucherUsed[usedIdx].minimum_amount,
          date_start: state.voucherUsed[usedIdx].date_start,
          date_end: state.voucherUsed[usedIdx].date_end
        })
      }
      return dataObj
    })

    payload.data = data
    if (paginated) {
      if (!state[type].data) {
        delete payload.type
        state[type] = payload
      } else {
        const oldData = state[type].data

        const newData = data
        oldData.forEach((old) => {
          const newIdx = newData.findIndex((newDt) => {
            return old.id === newDt.id
          })

          if (newIdx > -1) {
            newData.splice(newIdx, 1)
          }
        })
        state[type].data = oldData.concat(newData)
        state[type].nextPage = nextPage
        state[type].total = total
      }
    } else {
      delete payload.type
      state[type] = payload
    }
  },
  editVouchersByType (state, payload) {
    const { voucherId, type, additionalData } = payload

    if (state[type] && state[type].data && state[type].data.length) {
      const voucherIdx = state[type].data.findIndex((voucher) => {
        return voucher.id === voucherId
      })

      if (voucherIdx > -1) {
        const oldData = state[type].data[voucherIdx]
        const mergedData = {
          ...oldData,
          ...additionalData
        }

        state[type].data[voucherIdx] = JSON.parse(JSON.stringify(mergedData))
      }
    }
  },
  resetVouchersByType (state, payload) {
    const stateVoucherType = payload && payload.length ? payload : ['vouchersDiscount', 'vouchersShipping']

    stateVoucherType.forEach((stateData) => {
      if (state[stateData] && state[stateData].data) {
        const resetData = state[stateData].data.map((dt) => {
          const deletedObj = ['available', 'total_discount']

          deletedObj.forEach((obj) => {
            delete dt[obj]
          })
          return {
            ...dt,
            selectType: 'choose'
          }
        })

        state[stateData].data = resetData
      }
    })
  },
  resetVouchersStore (state, payload) {
    const storeIdx = state.vouchers.findIndex((voucher) => {
      return voucher.store_id === payload
    })
    state.vouchers.splice(storeIdx, 1)
  },
  addVoucherAfterCode (state, payload) {
    const { voucher, type } = payload

    if (state[type].data) {
      const voucherExistIdx = state[type].data.findIndex((vc) => {
        return vc.id === voucher.id
      })

      if (voucherExistIdx > -1) {
        state[type].data[voucherExistIdx] = {
          ...voucher
        }
      } else {
        state[type].data.unshift(voucher)
      }
    } else {
      state[type].data = [voucher]
    }
  },
  clearVouchersByType (state, payload) {
    state[payload] = {}
  },
  setVoucher (state, payload) {
    state.voucher = payload
  },
  setPopupVoucherModal (state, payload) {
    state.popupVoucherModal = payload
  },
  setVoucherUsed (state, payload) {
    const { storeId, voucherData } = payload

    const voucherUsedIdx = state.voucherUsed.findIndex((vu) => {
      return vu.storeId === storeId
    })
    if (voucherUsedIdx > -1) {
      const voucherIdx = state.voucherUsed[voucherUsedIdx].data.findIndex((v) => {
        return v.id === voucherData.id
      })

      if (voucherIdx > -1) {
        state.voucherUsed[voucherUsedIdx].data.splice(voucherIdx, 1)
      } else {
        state.voucherUsed[voucherUsedIdx].data.push(voucherData)
      }
    } else {
      state.voucherUsed.push({
        storeId,
        data: [voucherData]
      })
    }
  },
  removeVoucherUsedPerStore (state, payload) {
    const { storeId } = payload

    const voucherUsedStoreIdx = state.voucherUsed.findIndex((used) => {
      return used.storeId === storeId
    })

    if (voucherUsedStoreIdx === -1) {
      return
    }

    state.voucherUsed.splice(voucherUsedStoreIdx, 1)
  },
  setVoucherUsedByStore (state, payload) {
    const { storeId, data } = payload
    const voucherUsedIdx = state.voucherUsed.findIndex((vu) => {
      return vu.storeId === storeId
    })

    if (voucherUsedIdx > -1) {
      state.voucherUsed[voucherUsedIdx].data = data
    } else {
      state.voucherUsed.push({
        storeId,
        data
      })
    }
  },
  resetVoucherUsed (state) {
    state.voucherUsed = []
  },
  setToggleDetail (state, payload) {
    state.showDetail = payload
  },
  setPage (state, payload) {
    state.pageVoucher = payload
  },
  setTotalPage (state, payload) {
    state.totalPageVoucher = payload
  },
  setOnCheck (state, payload) {
    state.checkVoucher = payload
  }
}

export const getters = {
  listVouchers (state) {
    const list = []

    if (Object.keys(state.vouchersShipping).length && state.vouchersShipping.data.length !== 0) {
      list.push({
        ...state.vouchersShipping,
        label: 'Voucher Potongan Ongkos Kirim',
        slug: 'free-shipping-cost',
        type: 'free_shipping_cost'
      })
    }

    if (Object.keys(state.vouchersDiscount).length && state.vouchersDiscount.data.length !== 0) {
      list.push({
        ...state.vouchersDiscount,
        label: 'Voucher Diskon Produk',
        slug: 'discount',
        type: 'discount'
      })
    }

    return list
  },
  voucherUsedId (state) {
    return []
  },
  totalVoucherUsed (state) {
    return state.voucherUsed.reduce((acc, curr) => {
      return acc + (curr.data.length || 0)
    }, 0)
  },
  groupedVoucherUsedData (state) {
    const voucherData = []

    state.vouchers.forEach((voucher) => {
      const voucherUsedStoreIdx = state.voucherUsed.findIndex((used) => {
        return used.storeId === voucher.store_id
      })

      if (voucherUsedStoreIdx > -1) {
        const voucherUsedData = state.voucherUsed[voucherUsedStoreIdx].data

        voucherData.push({
          storeId: voucher.store_id,
          storeName: voucher.store_name,
          data: isArrayNotNullOrEmpty(voucher.data) ? voucher.data.filter((dt) => {
            return dt && voucherUsedData.some((usedData) => {
              return usedData.id === dt.id
            })
          }) : []
        })
      }
    })

    return voucherData
  },
  ungroupedVoucherUsedData (state) {
    const voucherData = []

    const filteredVoucherUsed = state.voucherUsed.filter((used) => {
      return !!used.data.length
    })
    filteredVoucherUsed.forEach((used) => {
      const voucherObj = {
        storeId: used.storeId,
        storeName: '',
        data: {}
      }

      used.data.forEach((dt) => {
        voucherObj.data = dt
        voucherData.push(voucherObj)
      })
    })

    return voucherData
  }
}

export const actions = {
  getVouchers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getVouchers(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setVouchers', {
              data: response.data.data.vouchers.data,
              page: response.data.data.vouchers.current_page
            })
            commit('setTotalPage', response.data.data.vouchers.last_page)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getVouchersAvailable ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getVouchersAvailable(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            let payloadVoucher = {
              store_id: payload.store_id,
              store_name: payload.store_name,
              page: payload.payload.params.page,
              paginated: payload.payload.params.paginated
            }

            if (payload.payload.params.paginated) {
              payloadVoucher = Object.assign(payloadVoucher, {
                ...response.data.data.vouchers
              })
            } else {
              payloadVoucher = Object.assign(payloadVoucher, {
                data: response.data.data.vouchers,
                current_page: 1
              })
            }

            commit('setVouchersAvailable', payloadVoucher)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getVouchersByType ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getVouchersByType(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      ).then((response) => {
        const res = response.data.data.vouchers
        const paginated = payload.data.paginated
        const type = payload.type === 'discount' ? 'vouchersDiscount' : 'vouchersShipping'

        let comPayload = {
          paginated,
          type
        }

        if (paginated) {
          comPayload = Object.assign(comPayload, {
            nextPage: mixin.methods.getPageFromUrl(res.next_page_url),
            total: res.total,
            data: res.data
          })
        } else {
          comPayload = Object.assign(comPayload, {
            nextPage: null,
            total: 1,
            data: res
          })
        }

        commit('setVouchersByType', comPayload)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  checkVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.checkVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  searchVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.searchVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  checkAvailabilityVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.checkAvailabilityVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  showVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.showVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setVoucher', response.data.data.voucher)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getUserVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getUserVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getUserVoucherSummary ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getUserVoucherSummary(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}
