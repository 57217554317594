import CheckoutBranchServices from '@/services/Checkout/branch'

export const state = () => ({
  cartGroupStoreBranches: []
  /**
   * {
   *  storeBranchId: '',
   *  storeBranches: []
   * }
   */
})

export const mutations = {
  setCartGroupStoreBranches (state, payload) {
    const storeBranches = payload
    state.cartGroupStoreBranches = storeBranches.map((branch) => {
      return {
        storeBranchId: branch.store_branch_id,
        previousStoreBranchId: '',
        storeBranches: branch.store_branches
      }
    })
  },
  modifyCartGroupStoreBranches (state, payload) {
    const { storeBranchId, fields } = payload

    const cartGroupStoreBranchIdx = state.cartGroupStoreBranches.findIndex((branch) => {
      return branch.storeBranchId === storeBranchId
    })

    if (cartGroupStoreBranchIdx === -1) {
      return
    }

    fields.forEach((field) => {
      const { name, value } = field

      state.cartGroupStoreBranches[cartGroupStoreBranchIdx][name] = value

      if (name === 'selectedBranch') {
        state.cartGroupStoreBranches[cartGroupStoreBranchIdx].previousStoreBranchId = state.cartGroupStoreBranches[cartGroupStoreBranchIdx].storeBranchId
        state.cartGroupStoreBranches[cartGroupStoreBranchIdx].storeBranchId = value.id
      }
    })
  }
}

export const actions = {
  getBranchesForCheckout ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutBranchServices.getBranchesForCheckout({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      },
      payload)
        .then((response) => {
          commit('setCartGroupStoreBranches', response.data.data.store_branches)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
