import CheckoutServices from '@/services/Checkout'
import { checkoutMethods } from '@/utils/helpers/checkout-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'

export const state = () => ({
  pageTakeInStore: false,
  enableShipping: true,
  scheduleStoreBranch: [],
  storeBranch: [],
  storeBranchPAS: [],
  shippingCourier: [],
  listPaymentMethods: [],
  installments: [],
  vouchers: [],
  listTotalPayment: 0,
  selectedBank: [],
  selectedAddress: {},
  branchSelected: 0,
  orderPayload: {},
  listItem: {},
  paymentDetail: {},
  selectedCourier: {},
  noteShipping: '',
  onCheck: false,
  usePoint: false,
  // All New Checkout
  isLoading: false,
  checkoutMethod: checkoutMethods.shipping.value,
  checkoutAvailability: {},
  availableCartsForm: [],
  selectedPaymentMethod: {},
  selectedPaymentAccount: {},
  selectedCCInstallment: {}
})

export const mutations = {
  setTakeInStore (state, payload) {
    state.pageTakeInStore = payload
  },
  setEnableShipping (state, payload) {
    state.enableShipping = payload
  },
  setScheduleStoreBranch (state, payload) {
    state.scheduleStoreBranch = payload
  },
  setStoreBranch (state, payload) {
    state.storeBranch = payload
    state.branchSelected = 0
  },
  setStoreBranchPAS (state, payload) {
    state.storeBranchPAS = payload
    if (state.pageTakeInStore) {
      state.branchSelected = 0
    }
  },
  setSelectedBranch (state, payload) {
    state.branchSelected = payload
  },
  setUnshiftBranch (state, payload) {
    state.storeBranch.splice(state.branchSelected, 1)
    state.storeBranch.splice(0, 0, payload)
  },
  setShippingCourier (state, payload) {
    const couriers = payload
    state.shippingCourier = couriers.map((courier) => {
      return {
        shippingCouriers: courier.shipping_couriers,
        storeBranchId: courier.store_branch_id
      }
    })
  },
  modifyShippingCourier (state, payload) {
    const { storeBranchId, fields } = payload

    const cartGroupStoreBranchIdx = state.shippingCourier.findIndex((branch) => {
      return branch.storeBranchId === storeBranchId
    })

    if (cartGroupStoreBranchIdx === -1) {
      return
    }

    fields.forEach((field) => {
      const { name, value } = field

      state.shippingCourier[cartGroupStoreBranchIdx][name] = value

      if (name === 'selectedBranch') {
        state.shippingCourier[cartGroupStoreBranchIdx].previousStoreBranchId = state.shippingCourier[cartGroupStoreBranchIdx].storeBranchId
        state.shippingCourier[cartGroupStoreBranchIdx].storeBranchId = value.id
      }
    })
  },
  setListPaymentMethods (state, payload) {
    state.listPaymentMethods = payload
    state.listTotalPayment = payload.length
  },
  setSelectedBank (state, payload) {
    state.selectedBank = payload
  },
  setSelectedAddress (state, payload) {
    state.selectedAddress = payload
    state.branchSelected = 0
  },
  setOrderPayload (state, payload) {
    state.orderPayload = payload
  },
  setAvailibility (state, payload) {
    state.shippingCourier = payload.courier_costs
    state.listItem = payload.carts
    state.paymentDetail = payload.payment_detail
    state.installments = payload.credit_card
      ? payload.credit_card.installments
      : []
    state.vouchers = payload.vouchers
  },
  setSelectedCourier (state, payload) {
    state.selectedCourier = payload
  },
  setNoteShipping (state, payload) {
    state.noteShipping = payload
  },
  setOnCheck (state) {
    state.onCheck = !state.onCheck
  },
  setUsePoint (state, payload) {
    state.usePoint = payload
  },
  setLoading (state, payload) {
    state.isLoading = payload
  },
  // All New Checkout
  setPaymentDetail (state, payload) {
    state.paymentDetail = payload
  },
  setCheckoutMethod (state, payload) {
    state.checkoutMethod = payload
  },
  setCheckoutAvailability (state, payload) {
    state.checkoutAvailability = payload
  },
  setAvailableCartsForm (state, payload) {
    const { availableCarts } = payload

    if (!isObjectNotEmptyOrNull(availableCarts) || !isArrayNotNullOrEmpty(availableCarts.store_branches)) {
      return
    }

    if (!isArrayNotNullOrEmpty(state.availableCartsForm)) {
      state.availableCartsForm = availableCarts.store_branches.map((cart) => {
        return {
          storeId: cart.store ? cart.store.id : '',
          storeBranchId: cart.id,
          previousStoreBranchId: '',
          selectedBranch: {},
          selectedCourier: {},
          shippingPickupNote: '',
          carts: cart.carts
        }
      })
    } else {
      availableCarts.store_branches.forEach((cart) => {
        const availableCartsFormIdx = state.availableCartsForm.findIndex((cartForm) => {
          return cart.id === cartForm.storeBranchId
        })

        if (availableCartsFormIdx === -1) {
          state.availableCartsForm.push({
            storeId: cart.store ? cart.store.id : '',
            storeBranchId: cart.id,
            previousStoreBranchId: '',
            selectedBranch: {},
            selectedCourier: {},
            shippingPickupNote: '',
            carts: cart.carts
          })
        } else {
          const oldCartForm = state.availableCartsForm[availableCartsFormIdx]

          state.availableCartsForm.splice(availableCartsFormIdx, 1, {
            ...oldCartForm,
            storeId: cart.store ? cart.store.id : '',
            storeBranchId: cart.id,
            previousStoreBranchId: oldCartForm.id,
            carts: cart.carts
          })
        }
      })
    }
  },
  editAvailableCartsForm (state, payload) {
    const { cartGroupId, fields } = payload

    const cartsFormIdx = state.availableCartsForm.findIndex((cartForm) => {
      return cartForm.storeBranchId === cartGroupId
    })

    if (cartsFormIdx === -1) {
      return
    }

    fields.forEach((field) => {
      const { name, value } = field

      state.availableCartsForm[cartsFormIdx][name] = value

      if (name === 'selectedBranch') {
        state.availableCartsForm[cartsFormIdx].previousStoreBranchId = state.availableCartsForm[cartsFormIdx].storeBranchId
        state.availableCartsForm[cartsFormIdx].storeBranchId = value.id
      }
    })
  },
  resetAvailableCartsForm (state, payload) {
    state.availableCartsForm = []
  },
  setSelectedPaymentMethod (state, payload) {
    state.selectedPaymentMethod = payload
  },
  setSelectedPaymentAccount (state, payload) {
    state.selectedPaymentAccount = payload
  },
  setSelectedCCInstallment (state, payload) {
    state.selectedCCInstallment = payload
  }
}

export const getters = {
  pickupAtStore (state) {
    return state.checkoutMethod === checkoutMethods.pickup.value
  },
  checkoutAvailableCarts (state) {
    if (!isObjectNotEmptyOrNull(state.checkoutAvailability)) {
      return []
    }

    if (!isObjectNotEmptyOrNull(state.checkoutAvailability.available_carts)) {
      return []
    }

    return state.checkoutAvailability.available_carts.store_branches
  },
  unavailableCarts (state) {
    if (!isObjectNotEmptyOrNull(state.checkoutAvailability)) {
      return {}
    }

    const unavailableData = Object.keys(state.checkoutAvailability)
    .filter(key => key.includes('unavailable'))
    .reduce((obj, key) => {
        obj[key] = state.checkoutAvailability[key];
        return obj;
    }, {})

    return unavailableData
  },
  checkoutPaymentMethods (state) {
    if (!isObjectNotEmptyOrNull(state.checkoutAvailability)) {
      return []
    }

    return state.checkoutAvailability.payment_methods
  },
  checkoutTotalProduct (state) {
    if (!isObjectNotEmptyOrNull(state.checkoutAvailability)) {
      return 0
    }

    if (!isObjectNotEmptyOrNull(state.checkoutAvailability.available_carts)) {
      return 0
    }

    let totalProduct = 0

    state.checkoutAvailability.available_carts.store_branches.forEach((branch) => {
      totalProduct += branch.carts.length
    })

    return totalProduct
  }
}

export const actions = {
  getScheduleStoreBranch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getScheduleStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit(
              'setScheduleStoreBranch',
              response.data.data.store_branch_schedule
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getStoreBranch ({ commit }, payload) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      CheckoutServices.getStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setStoreBranch', response.data.data.store_branches)
          }
          commit('setLoading', false)
          resolve(response)
        })
        .catch((error) => {
          commit('setLoading', false)
          console.log(error)
          reject(error)
        })
    })
  },
  getStoreBranchPAS ({ commit }, payload) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      CheckoutServices.getStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setStoreBranchPAS', response.data.data.store_branches)
          }
          commit('setLoading', false)
          resolve(response)
        })
        .catch((error) => {
          commit('setLoading', false)
          console.log(error)
          reject(error)
        })
    })
  },
  getProductStoreBranch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getProductStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductStoreBranch', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getShippingCourier ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getShippingCourier(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setShippingCourier', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getListPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getListPayment(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setListPaymentMethods', response.data.data.payment_methods)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getItemAvailability ({ commit }, payload) {
    commit('setOnCheck')
    return new Promise((resolve, reject) => {
      CheckoutServices.getItemAvailability(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setAvailibility', response.data.data.checkout)
            commit(
              'setListPaymentMethods',
              response.data.data.checkout.payment_methods || []
            )
          }
          commit('setOnCheck')
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCheckoutAvailability ({ commit }, payload) {
    commit('setOnCheck')
    return new Promise((resolve, reject) => {
      CheckoutServices.getCheckoutAvailability(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setCheckoutAvailability', response.data.data.checkout)
            commit('setAvailableCartsForm', {
              availableCarts: response.data.data.checkout.available_carts
            })
            commit('setPaymentDetail', response.data.data.checkout.payment)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          commit('setOnCheck')
        })
    })
  }
}
