export const checkoutMethods = {
  shipping: {
    label: 'Dikirim',
    value: 'shipping',
    keyAvailable: 'shipping_is_available',
    actionLabel: 'dikirim'
  },
  pickup: {
    label: 'Ambil Di Toko',
    value: 'pickup',
    keyAvailable: 'pickup_is_available',
    actionLabel: 'diambil'
  }
}

export const paymentMethods = {
  bankTransfer: {
    label: 'Bank Transfer',
    value: 'bank_transfer'
  },
  virtualAccount: {
    label: 'Virtual Account',
    value: 'virtual_account'
  },
  creditCard: {
    label: 'Kartu Kredit',
    value: 'credit_card'
  },
  edc: {
    label: 'EDC',
    value: 'edc'
  },
  cash: {
    label: 'Tunai',
    value: 'cash'
  }
}

export const defaultCourierForCheckoutFulfilled = {
  code: 'recommendation',
  service: 'REG'
}
