import PartnerService from '@/services/Partner'

export const actions = {
  getPartners ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      PartnerService.getPartners(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAroundYouPartners ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      PartnerService.getNearestPartners(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
