export const storeTypes = {
  brandPartner: {
    label: 'Brand',
    value: 'brand_partner'
  },
  storePartner: {
    label: 'Toko',
    value: 'store_partner'
  },
  store: {
    label: 'Store Partner',
    value: 'store_partner'
  },
  brand: {
    label: 'Brand Partner',
    value: 'brand_partner'
  }
}

export const storeFileNames = {
  logo: 'logo',
  coverImage: 'cover_image'
}

export const storeSimpleType = {
  brand_partner: 'brand',
  store_partner: 'store'
}

export const optOrderBy = [
  { label: 'Terbaru', value: 'latest' },
  { label: 'Terpopuler', value: 'most_popular' },
  { label: 'Terdekat', value: 'nearest' },
  { label: 'A-Z', value: 'name_asc' },
  { label: 'Z-A', value: 'name_desc' }
]

export const catalogPartnerSections = {
  allPartner: {
    value: 'all-partner'
  },
  aroundYou: {
    value: 'around-you'
  }
}

export const defaultStoreEmptyRating = 'N/A'
export const defaultStoreLocationLabel = 'Indonesia'

export const getStoreBadgeFromStoreType = (storeType) => {
  switch (storeType) {
    case storeTypes.storePartner.value:
      return '/img/icon/store_partner.svg'
    case storeTypes.brandPartner.value:
      return '/img/icon/brand_partner.svg'
    default:
      return ''
  }
}

export const getStoreTypeLabelFromStoreType = (storeType) => {
  const selectedStoreType = Object.values(storeTypes).find((item) => {
    return item.value === storeType
  })

  return selectedStoreType ? selectedStoreType.label : ''
}
